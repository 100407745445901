.main-con-for-subs {
    max-width: 100vw;
}

.sub-con-for-sub {
    width: 925px;
    margin: 0 auto;
}

.r-text {
    color: var(--color);
    font-weight: 700;
    font-size: 45px;
    letter-spacing: 0.1rem;
    font-family: "Source Sans Pro";
}

.b-text {
    color: black;
    font-weight: 400;
    font-size: 45px;
    font-family: "Source Sans Pro";
}

.rs-text {
    font-size: 27px;
    font-weight: 500;
    color: var(--color);
    font-family: "Source Sans Pro";
    font-style: italic;
}

.container-of-tabol {
    margin-top: 25px;

    .ant-table-container table>thead>tr:first-child th:first-child {
        text-align: start !important;
        color: black !important;
        font-size: 25px !important;
        font-weight: 700 !important;
        background-color: white !important;
        font-family: "Source Sans Pro";
    }

    .ant-table-thead>tr>th {
        background-color: var(--color);
        color: white;
        font-size: 20px;
        font-weight: 600;
        font-family: "Source Sans Pro";
    }

    .title-style {
        color: white;
    }

    .subtitle-style {
        color: white;
        font-size: 13px;
        font-weight: 300;
    }

    .feature-col {
        float: left;
        color: #231f20;
        font-size: 18px;
        font-family: "Source Sans Pro";
    }
}

.tick-adjuster {
    color: #231f20;
    font-size: 13px;
}

.mid-row {
    font-weight: 900;
    background-color: #e6e7e9;
}

.last-three-lines {
    display: flex;
    flex-direction: column;
    color: #231f20;
}

.btnNlines {
    display: flex;
    justify-content: space-between;
    margin-top: 6vh;
    margin-bottom: 10vh;
}

.req-price-btn {
    padding: 10px;
    background-color: var(--color);
    border: none;
    color: white;
    font-size: 17px;
    font-weight: 700;
}

.sup-color {
    color: var(--color);
    font-weight: 900;
}

.main-for-mob-view {
    display: none;
}


@media screen and (max-width: 1080px) {
    .container-of-tabol {
        display: none;
    }

    .main-for-mob-view {
        display: block;
    }

    // adjusting end btn and lines
    .btnNlines {
        flex-direction: column-reverse;
    }

    .last-three-lines {
        margin-top: 40px;
    }

    // adjusting heading
    .headline-con {
        width: 45vw;
        margin-top: 40px;
    }

    .b-text {
        line-height: 44px;
    }

    .rs-text {
        font-weight: 600;
    }

    // going for the main content
    .first-for-mob {
        margin-top: 5vh;
    }

    .lineformob {
        width: 100%;
        border: 0.5px solid #939598;
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .inner-list {
        padding-inline-start: 10px;
        list-style-type: '- ';
    }

    .basic-report {
        font-size: 1.9rem;
        font-weight: 700;
        margin-bottom: 0px !important;
    }

    .outer-list {
        font-size: 1.2rem;
        color: #231f20;
    }

    .pay-span {
        font-size: 1.5rem;
        font-weight: 500;

    }

    .span-red {
        color: var(--color);
        display: block;
        font-size: 1.2rem;
        font-family: 'Source Sans Pro';
        font-style: italic;
        font-weight: 600;
    }

    .sup-for-mobversion {
        color: var(--color);
        font-weight: 700;
        font-size: 0.8rem;
    }

    .sup-for-lastpara-mob {
        color: var(--color);
        font-weight: 900;
        font-size: 1rem;
    }

    .sub-con-for-sub {
        width: 85%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 740px) {
    .headline-con {
        width: 50vw;
        margin-top: 40px;
    }

    .sub-con-for-sub {
        width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 670px) {
    .headline-con {
        width: 60vw;
        margin-top: 40px;
    }

    .sub-con-for-sub {
        width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 556px) {
    .headline-con {
        width: 70vw;
        margin-top: 40px;
    }

    .sub-con-for-sub {
        width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 480px) {
    .headline-con {
        width: 80vw;
        margin-top: 40px;
    }

    .sub-con-for-sub {
        width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 420px) {
    .headline-con {
        width: 95vw;
        margin-top: 30px;
    }

    .sub-con-for-sub {
        width: 90%;
        margin: 0 auto;
    }

    .rs-text {
        font-weight: 600;
    }

    .r-text {
        color: var(--color);
        font-weight: 600;
        font-size: 40px;
        letter-spacing: 0rem;
        font-family: "Source Sans Pro";
        font-style: italic;
    }

    .b-text {
        color: black;
        font-weight: 400;
        font-size: 40px;
    }

    // going for the main content
    .first-for-mob {
        margin-top: 2vh;
    }


    .basic-report {
        font-size: 1.7rem;
        font-weight: 700;
        margin-bottom: 0px !important;
    }

    .outer-list {
        font-size: 1.1rem;
        color: #231f20;
        padding-left: 40px;
    }

    .pay-span {
        font-size: 1.3rem;
        font-weight: 500;

    }

    .span-red {
        color: var(--color);
        display: block;
        font-size: 1.1rem;
        font-family: 'Source Sans Pro';
        font-style: italic;
        font-weight: 600;
    }

}

@media screen and (max-width: 350px) {
    .headline-con {
        width: 95vw;
        margin-top: 30px;
    }

    .rs-text {
        font-weight: 600;
    }

    .r-text {
        color: var(--color);
        font-weight: 600;
        font-size: 30px;
        letter-spacing: 0rem;
        font-family: "Source Sans Pro";
        font-style: italic;
    }

    .b-text {
        color: black;
        font-weight: 400;
        font-size: 30px;
    }
}