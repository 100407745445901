.content-cont {
  min-width: 80%;
  max-width: 100%;
  display: flex;
}

.inner-container {
  margin-top: -5px;
}

.main-con-two {
  display: flex;
  flex-direction: column;
  margin-top: 1%;
}

.upload-container {
  margin-top: 0.5%;
}

.vol-II {
  display: flex;
  flex-direction: column;
  width: 62.5rem;
}

.big-head-normal {
  font-size: 1.7rem;
  font-weight: 700;
}

.big-head-spec {
  font-size: 2rem;
}

.normal-head-norm {
  font-size: 1.1rem;
  font-weight: 700;
}

.normal-head-spec {
  font-size: 1.4rem;
}

.descriptPgOne {
  font-size: 1.3rem;
  text-align: justify !important;
  color: black;
}

.outer-bulletHead {
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: 1.5%;
}

.spec-bulletHead {
  font-size: 1.4rem;
}

.bullet-it-I {
  display: flex;
  margin-top: 0.5%;
  height: 30px;
}

.bullet-it-II {
  display: flex;
  margin-top: 0.5%;
  height: 85px;
}

.bullet-it-III {
  display: flex;
  margin-top: 0.5%;
  height: 85px;
}

.bullet-it-IV {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 5px 0px 5px 0px;
}

.bullet-it-iv-head {
  font-size: 1.1rem;
  font-weight: 700;
}

.bullet-it-iv-inner {
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.owner-num-div {
  margin: 0px !important;
}

.bullet-container {
  display: flex;
  flex-direction: column;
}

.red-col {
  cursor: pointer;
  color: red;
  margin-left: 5px;
}

.bullet-f {
  font-weight: 700;
  min-width: 28%;
  width: 28%;
  max-width: 28%;
  font-size: 1.4rem;
}

.more-lines-in-bullet {
  display: flex;
  flex-direction: column;
}

.bullet-main {
  font-size: 1.4rem;
  font-weight: 600;
}

.upload-abv-pag {
  font-size: 1.4rem;
  font-weight: 700;
  color: black;
}

.upload-spec {
  font-size: 1.5rem;
}

.drag-doted {
  height: unset !important;
  background-color: #fa0000 !important;
  display: flex;
  border: 4px dashed black !important;
  border-radius: 15px !important;
  justify-content: center;
  margin-top: 1%;
}

.dark {
  color: white !important;
}

.mr-to {
  margin-top: 1%;
}

.uploaded-files-head {
  font-size: 1.1rem;
  font-weight: 700;
}

.num-btn-color {
  background: black !important;
}

.bullets-texts {
  font-size: 1.1rem;
  font-weight: bold;
}

.after-file-uploaded {
  font-size: 1.1rem;
  font-weight: bold;
}

.upload-file {
  cursor: pointer;
  font-size: 20px;
  margin: 3px 0 0 5px;
  font-weight: bold;
}

.red {
  color: #fa0000;
}

.bullet-tick {
  font-size: 1.3rem;
}

.mb {
  margin-bottom: 1%;
}

.delete-icon {
  color: #fa0000;
  margin: 1%;
}

.tower-text {
  margin-bottom: 1rem;
  font-size: 18px;
  font-weight: bold;
  color: red;
}

.phone-number-area-code {
  font-weight: 600;
  min-width: 28%;
  max-width: 28%;
  font-size: 1.4rem;
}

@media (max-width: 1280px) {
  .vol-II {
    margin-right: 5rem;
  }
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-upload-wrapper .ant-upload-drag {
  border: none;
}

@media (max-width: 480px) {
  .inner-container {
    margin-left: -5.2rem;
    margin-right: -2.8rem;
  }

  .upload-abv-pag {
    font-size: 1rem !important;
  }

  .upload-spec {
    font-size: 1.2rem !important;
  }

  .bullet-f {
    font-size: 1.3rem;
  }

  .bullet-main {
    font-size: 1.3rem;
  }

  .vol-II {
    // margin-right: 0;
  }
}