.main-con-six {
  display: flex;
  flex-direction: column;
  margin-top: 1%;
}

.content-cont-six {
  min-width: 80%;
  max-width: 100%;
  display: flex;
  padding-left: 7%;
}

.mr-six {
  margin-right: 30px;
}

.big-head-normal-six {
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 1.4;
  margin-left: -1%;
}

.big-head-spec-six {
  font-size: 2rem;
}

.cont-arr-six {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.leftie-six {
  width: 30%;
}

.rightie-six {
  width: 30%;
  margin-top: 2%;
}

.main-for-six {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1%;
}

.span-six {
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
}

.num-btn-color {
  background: black !important;
}

@media (min-width: 360px) and (max-width: 389px) {
  .main-con-six {
    margin-top: 2%;
  }

  .content-cont-six {
    padding-left: 2%;
    max-width: unset !important;
  }

  .vol-II-six {
    width: 85%;
    margin-left: -4.5%
  }

  .big-head-normal-six {
    margin-left: 0%;
    font-size: 1.2rem !important;
  }

  .big-head-spec-six {
    font-size: 1.4rem !important;
  }

  .rightie-six {
    margin-left: -38%;
    width: 135% !important;
    margin-top: 1rem !important;
  }
}

@media (min-width: 390px) and (max-width: 410px) {
  .main-con-six {
    margin-top: 2%;
  }

  .content-cont-six {
    padding-left: 2%;
    max-width: unset !important;
  }

  .vol-II-six {
    width: 90%;
    margin-left: -4.5%
  }

  .big-head-normal-six {
    margin-left: 0%;
    font-size: 1.2rem !important;
  }

  .big-head-spec-six {
    font-size: 1.4rem !important;
  }

  .rightie-six {
    margin-left: -33%;
    width: 130% !important;
    margin-top: 1rem !important;
    margin-bottom: 5%;
  }
}

@media (min-width: 411px) and (max-width: 479px) {
  .main-con-six {
    margin-top: 2%;
  }

  .content-cont-six {
    padding-left: 6%;
    max-width: unset !important;
  }

  .vol-II-six {
    width: 75%;
    margin-left: -4%
  }

  .big-head-normal-six {
    margin-left: 0%;
    font-size: 1.2rem !important;
  }

  .big-head-spec-six {
    font-size: 1.4rem !important;
  }

  .rightie-six {
    margin-left: -33%;
    width: 125% !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem;
  }
}

@media (min-width: 480px) and (max-width: 499px) {
  .main-con-six {
    margin-top: 2%;
  }

  .content-cont-six {
    padding-left: 6%;
    max-width: unset !important;
  }

  .vol-II-six {
    width: 85%;
    margin-left: -3%
  }

  .big-head-normal-six {
    margin-left: 0%;
    font-size: 1.2rem !important;
  }

  .big-head-spec-six {
    font-size: 1.4rem !important;
  }

  .rightie-six {
    margin-left: -26%;
    width: 118% !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem;
  }
}

@media (min-width: 500px) and (max-width: 519px) {
  .main-con-six {
    margin-top: 2%;
  }

  .content-cont-six {
    padding-left: 6%;
    max-width: unset !important;
  }

  .vol-II-six {
    width: 85%;
    margin-left: -2.5%
  }

  .big-head-normal-six {
    margin-left: 0%;
    font-size: 1.4rem !important;
  }

  .big-head-spec-six {
    font-size: 1.6rem !important;
  }

  .rightie-six {
    margin-left: -26%;
    width: 118% !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem;
  }
}

@media (min-width: 520px) and (max-width: 549px) {
  .main-con-six {
    margin-top: 2%;
  }

  .content-cont-six {
    padding-left: 6%;
    max-width: unset !important;
  }

  .vol-II-six {
    width: 85%;
    margin-left: -3%
  }

  .big-head-normal-six {
    margin-left: 0%;
    font-size: 1.4rem !important;
  }

  .big-head-spec-six {
    font-size: 1.6rem !important;
  }

  .rightie-six {
    margin-left: -20%;
    width: 110% !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem;
  }
}

@media (min-width: 550px) and (max-width: 599px) {
  .main-con-six {
    margin-top: 2%;
  }

  .content-cont-six {
    padding-left: 6%;
    max-width: unset !important;
  }

  .vol-II-six {
    width: 80%;
    margin-left: -2.5%
  }

  .big-head-normal-six {
    margin-left: 0%;
    font-size: 1.4rem !important;
  }

  .big-head-spec-six {
    font-size: 1.6rem !important;
  }

  .rightie-six {
    margin-left: -15%;
    width: 100% !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem;
  }
}

@media (min-width: 600px) and (max-width: 649px) {
  .main-con-six {
    margin-top: 2%;
  }

  .content-cont-six {
    padding-left: 6%;
    max-width: unset !important;
  }

  .vol-II-six {
    width: 85%;
    margin-left: -2%
  }

  .big-head-normal-six {
    margin-left: 0%;
    font-size: 1.4rem !important;
  }

  .big-head-spec-six {
    font-size: 1.6rem !important;
  }

  .rightie-six {
    margin-left: -8%;
    width: 90% !important;
    margin-top: 3rem !important;
    margin-bottom: 1rem;
  }
}

@media (min-width: 650px) and (max-width: 699px) {
  .main-con-six {
    margin-top: 2%;
  }

  .content-cont-six {
    padding-left: 6%;
    max-width: unset !important;
  }

  .vol-II-six {
    width: 85%;
    margin-left: -2%
  }

  .big-head-normal-six {
    margin-left: 0%;
    font-size: 1.4rem !important;
  }

  .big-head-spec-six {
    font-size: 1.6rem !important;
  }

  .rightie-six {
    margin-left: -8%;
    width: 90% !important;
    margin-top: 3rem !important;
    margin-bottom: 1rem;
  }
}

@media (min-width: 700px) and (max-width: 739px) {
  .main-con-six {
    margin-top: 2%;
  }

  .content-cont-six {
    padding-left: 6%;
    max-width: unset !important;
  }

  .vol-II-six {
    width: 85%;
    margin-left: -2%
  }

  .big-head-normal-six {
    margin-left: 0%;
    font-size: 1.4rem !important;
  }

  .big-head-spec-six {
    font-size: 1.6rem !important;
  }

  .rightie-six {
    margin-left: -2%;
    width: 80% !important;
    margin-top: 3rem !important;
    margin-bottom: 1rem;
  }
}

@media (min-width: 740px) and (max-width: 767px) {
  .main-con-six {
    margin-top: 2%;
  }

  .content-cont-six {
    padding-left: 6%;
    max-width: unset !important;
  }

  .vol-II-six {
    width: 85%;
    margin-left: -2%
  }

  .big-head-normal-six {
    margin-left: 0%;
    font-size: 1.4rem !important;
  }

  .big-head-spec-six {
    font-size: 1.6rem !important;
  }

  .rightie-six {
    margin-left: -2%;
    width: 80% !important;
    margin-top: 3rem !important;
    margin-bottom: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 799px) {
  .main-con-six {
    margin-top: 2%;
  }

  .big-head-normal-six {
    margin-left: -1.5%;
  }

  .rightie-six {
    margin-left: -2%;
    width: 75% !important;
    margin-top: 3rem !important;
    margin-bottom: 1rem;
  }
}

@media (min-width: 800px) and (max-width: 899px) {
  .main-con-six {
    margin-top: 2%;
  }

  .big-head-normal-six {
    margin-left: -1.5%;
  }

  .rightie-six {
    width: 70% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 900px) and (max-width: 949px) {
  .main-con-six {
    margin-top: 2%;
  }

  .big-head-normal-six {
    margin-left: -1.5%;
  }

  .rightie-six {
    width: 65% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 950px) and (max-width: 999px) {
  .main-con-six {
    margin-top: 2%;
  }

  .big-head-normal-six {
    margin-left: -1%;
  }

  .rightie-six {
    width: 60% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 1000px) and (max-width: 1099px) {
  .big-head-normal-six {
    margin-left: -1%;
  }

  .rightie-six {
    width: 55% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 1100px) and (max-width: 1151px) {
  .big-head-normal-six {
    margin-left: -1%;
  }

  .rightie-six {
    width: 45% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 1152px) and (max-width: 1359px) {
  .rightie-six {
    width: 45% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 1360px) and (max-width: 1365px) {
  .big-head-normal-six {
    margin-left: -0.9%;
  }

  .rightie-six {
    width: 35% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 1366px) and (max-width: 1499px) {
  .big-head-normal-six {
    margin-left: -0.9%;
  }

  .rightie-six {
    width: 35% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 1500px) and (max-width: 1679px) {
  .big-head-normal-six {
    margin-left: -0.8%;
  }

  .rightie-six {
    width: 35% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 1680px) and (max-width: 1699px) {
  .big-head-normal-six {
    margin-left: -0.7%;
  }

  .rightie-six {
    width: 35% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 1700px) and (max-width: 1849px) {
  .big-head-normal-six {
    margin-left: -0.7%;
  }

  .rightie-six {
    width: 30% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 1850px) and (max-width: 1949px) {
  .big-head-normal-six {
    margin-left: -0.6%;
  }

  .rightie-six {
    width: 27% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 1950px) and (max-width: 2399px) {
  .big-head-normal-six {
    margin-left: -0.6%;
  }

  .rightie-six {
    width: 25% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 2400px) and (max-width: 2559px) {
  .big-head-normal-six {
    margin-left: -0.5%;
  }

  .rightie-six {
    width: 23% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 2560px) and (max-width: 2879px) {
  .big-head-normal-six {
    margin-left: -0.5%;
  }

  .rightie-six {
    width: 20% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 2880px) and (max-width: 3439px) {
  .big-head-normal-six {
    margin-left: -0.4%;
  }

  .rightie-six {
    width: 20% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 3440px) and (max-width: 3839px) {
  .big-head-normal-six {
    margin-left: -0.3%;
  }

  .rightie-six {
    width: 15% !important;
    margin-top: 2rem !important;
  }
}

@media (min-width: 3840px) {
  .big-head-normal-six {
    margin-left: -0.3%;
  }

  .rightie-six {
    width: 15% !important;
    margin-top: 2rem !important;
  }
}