.ant-col:hover {
	border-color: #e4003b !important;
}

.ant-form-item-control-input:hover {
	border-color: #e4003b !important;
}

.ant-form-item-control:hover {
	border-color: #e4003b !important;
}

.ant-form-item-control-input-content:focus {
	border-color: #e4003b !important;
	box-shadow: #e4003b !important;
}

.login-input-field:hover {
	border-color: #e4003b !important;
}

.center-aligner {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 80vh;
	justify-content: center;
}

.terms-container {
	display: flex;
	max-width: 522px;

	.ant-form-item-control {
		flex-direction: column-reverse;
	}
}

.checkbx-adjuster {
	margin: 5px;
}

.login-card {
	display: flex;
	border-radius: 25px;
	border: 2px solid lightgray;
	margin-top: 2vh;
	align-items: center;
	background-color: white;
	padding: 10px;
	justify-content: space-around;
}

.para-handler {
	display: inline-block;
	color: #e4003b;
	font-size: 13px;
}

.last-text {
	margin-top: 10px;
	margin-left: 24px;
	text-align: left;
}

.email-adjuster-for-reset-modal {
	.ant-form-item-label>label {
		font-size: 18px;
		margin-top: 5px;
	}
}

.width-for-form {
	width: 15rem;
}

.justify-text {
	text-align: left;
	font-weight: 800;
	width: 27rem;
	margin-bottom: 0rem;
	margin-top: -0.2rem;
}
.checkbox-container{
	display: flex;
    gap: 0.5rem;
    align-items: flex-start;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-checkbox-checked:after {
	border: 2px solid red;
}

.width-adjuster-for-login {
	width: 520px;
}

.ant-checkbox-inner {
	width: 24px;
	height: 24px;
	border-width: 3px;
	border-radius: 5px;
	border-color: black !important;
}


@media only screen and (max-width: 600px) {
	.width-adjuster-for-login {
		max-width: 90%;
	}

	.width-for-form {
		max-width: 50%;
	}
}

/* Media Query for Mobile Devices */
@media (min-width: 360px) and (max-width: 389px) {
	.login-cn-logo {
		margin-bottom: 16% !important;
		margin-left: 3%;
	}

	.last-text {
		text-align: left !important;
	}

	.justify-text {
		text-align: left !important;
		width: 19rem;
	}

	.para-handler {
		width: max-content;
	}
}

@media (min-width: 390px) and (max-width: 410px) {
	.login-cn-logo {
		margin-bottom: 12% !important;
		margin-left: 3%;
	}

	.last-text {
		text-align: left !important;
	}

	.justify-text {
		text-align: left !important;
		width: 20rem;
	}

	.para-handler {
		width: max-content;
	}
}

@media (min-width: 411px) and (max-width: 479px) {
	.login-cn-logo {
		margin-bottom: 9% !important;
		margin-left: 4%;
	}

	.last-text {
		text-align: left !important;
	}

	.justify-text {
		text-align: left !important;
		width: 20rem;
	}

	.para-handler {
		width: max-content;
	}
}

@media (min-width: 480px) and (max-width: 767px) {
	.login-cn-logo {
		margin-bottom: 6% !important;
		margin-left: 3%;
	}
}