.footer {
  background-color: #3b3c3e;
  border-top: 1px solid #e5e5e5;
  padding: 95px 0;
  position: relative;
  display: block;
}
.footer p {
  text-align: left !important;
  text-justify: inter-word !important;
}

footer a:hover {
  color: white;
}

a.anchor {
  color: var(--color);
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  font-size: 25px;
}

.footer-new {
  line-height: 2rem;
  flex-basis: 25%;
  z-index: 0;
  padding-right: 10.5px;
  color: white;
  font-family: inherit;
  font-size: 18px;
  display: inline-block;
}

ul.footer-links,
.anchor-for-fotter {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  display: flex;
}

.Footer-div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.font-adjuster {
  font-family: "Source Sans Pro";
  font-size: 18px;
  color: #fff;
  line-height: 36px;
}

.footer-text h1 {
  text-align: start !important;
  color: white !important;
  margin-left: -1px;
}

.footer-text,
.footer-links a {
  color: white;
}

@media (max-width: 767px) {
  .footer-new {
    flex-basis: 100%;
  }

  .row {
    flex-direction: column;
  }
}

.col {
  flex-basis: 25%;
  padding: 10px;
  margin-left: 7vw;
}

.row {
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.font-adjuster-for-footer {
  font-size: 2rem;
  font-weight: 700;
}

/* Media Query for Mobile Devices */
@media (min-width: 360px) and (max-width: 2047px) {
  .footer {
    padding: 20px 0 !important;
  }
}

@media (min-width: 2048px) {
  .footer {
    padding: 50px 0 !important;
  }

  .font-adjuster-for-footer {
    font-size: 3rem;
  }

  .footer-font {
    font-size: 1.5rem;
  }

  .anchor-for-fotter {
    font-size: 1.5rem;
  }
}
