.main-container-aboutus {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;

}

.content-container-aboutus {
    width: 78%;
}

.heading-con-aboutus {
    font-size: 54px;
    letter-spacing: 0.1rem;
    margin-top: 2vw;
}

.about {
    color: var(--color);
    font-weight: 700;


}

.about-us-content-con {
    display: flex;
    margin-top: 2vh;
    font-size: 22px;
    width: 100%;

}

.left-sec {
    width: 50%;
    padding: 0px 20px 0px 0px;

}

.right-sec {
    padding: 0px 0px 0px 2px;
    margin-left: 10px;
    width: 50%;

}

.paraI {
    text-align: start;
    padding: 0px 25px 0px 7px;
    margin-top: 5vh;
}

.subject-adjuster {
    padding-left: 7px;
    color: var(--color);
    font-weight: bold;
    font-size: 25px;
}

.list-container {
    line-height: 2.2rem;
}

.img-adjuster {
    height: 270px;
    width: 270px;
    margin-left: 7px;

}

.fact-container {
    padding: 15px;
    background-color: var(--color);
    color: white;
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}

.fact {
    font-size: 30px;
    font-weight: 700;
}

.img-con {
    display: flex;
}

@media only screen and (max-width : 800px) {
    .about-us-content-con {
        display: block;
    }

    .left-sec {
        width: 100%;
    }

    .right-sec {
        width: 100%;
    }
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .main-container-aboutus {
        padding: 1rem !important;
    }

    .content-container-aboutus {
        width: auto !important;
    }

    .subject-adjuster {
        padding-left: 0px !important;
    }

    .list-container {
        line-height: 1.8rem !important;
    }

    .heading-con-aboutus {
        font-size: 35px !important;
        text-align: left !important;
        margin-top: 0px !important;
    }

    .about-us-content-con {
        margin-top: 1vh !important;
    }

    .paraI {
        padding: 0px !important;
        margin-top: 0px !important;
    }

    .img-con {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }

    .right-sec {
        padding: 0px !important;
        margin-left: 0px !important;
    }

    .left-sec {
        padding: 0px !important;
        margin-left: 0px !important;
    }

    .fact-container {
        margin-left: 0px !important;
    }
}