.main-container-create-account {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
}

.content-container {
    max-width: 78%;
    overflow: hidden;
}

.heading-con {
    font-size: 54px;
    letter-spacing: 0.1rem;
    // line-height: 41rem;
    margin-top: 30px;
}

.contact {
    color: var(--color);
    font-weight: 700;
}

.contact-us-content-con {
    display: flex;
    justify-content: space-between;
    margin-top: 5vh;
    // padding: 0px 25px;
    font-size: 22px;
    width: 100%;
}

.form-con {
    width: 49%;
    padding: 0px 20px 0px 0px;
}

.right-side {
    padding: 0px 0px 0px 2px;
    width: 51%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.facts {
    font-size: 30px;
    font-weight: 700;
}
.padding411{
    padding: 4px 11px;
}

.form-constz {
    width: 77%;
    display: flex;
    justify-content: flex-start;

    .form-for-signup {
        width: 100%;
    }

    .ant-form-item-control-input {
        width: 150%;
        display: flex;
        justify-content: center;
    }

    .new-ac-create-btn-con {
        display: flex;
        // justify-content: center;

        .create-acc-btn:focus,
        .create-acc-btn:active {
            text-decoration: none;
            background: var(--color) !important;
            color: white;
            border: none;
        }

        .create-acc-btn:hover {
            filter: contrast(115%) !important;
        }
    }

    .ant-form-item {
        margin: 0px 0px 0px 0px !important;
        width: 100%;
    }

    .ant-form-horizontal .ant-form-item-label {
        flex-grow: 0;
        max-width: 40% !important;
        text-align: left !important;
    }

    .ant-form-item-control-input-content {
        display: flex;
        justify-content: flex-start;

        input {
            // height: 70px;
            font-size: 1.7rem;
            width: 100%;
        }
    }

    .icon-adjusting {
        .ant-form-item-control-input-content {
            input {
                border: 1px solid #d9d9d9; // for phone no field
                border-radius: 6px;
            }

        }

    }

    .email-adjusting {
        .border-remover {
            border-right: 1px solid white !important;
        }



    }

    .ant-input .hant-input-status-success {
        padding: 1.8em 2em !important;
    }

    ::placeholder {
        color: #090909;
        opacity: 1;
        /* Firefox */
        font-size: 1.3rem;
    }



}

.create-acc-btn {
    background-color: var(--color);
    color: white;
    font-weight: 500;
    border-radius: 5px;
    width: 100px;
    height: 40px;
    // float: right;
}

.submit-btn:hover {
    background-color: var(--color) !important;
    color: white !important;
}

.captcha {
    margin-top: 10px;
}

.image-con-us {
    width: 30vw;
    height: 30vh;
    // margin-top: 50px;
}
.height70{
    height: 70px !important;
}
:where(.css-dev-only-do-not-override-1m62vyb).ant-modal .ant-modal-title{
    font-weight: 700;
    font-size: 18px;
}
@media only screen and (max-width: 700px) {
    .contact-us-content-con {
        font-size: 22px;
    }

    .right-side {
        width: 100%;
    }

    .name-adjuster {
        flex-direction: column;
    }

    .form-con {
        width: 100%;
        margin-bottom: 5vh;
    }
}

.new-customer {
    width: 50%;
    background-color: #e5e5e5;
    padding: 30px;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.create-btn {
    padding: 12px 22px;
    background-color: var(--color);
    border: none;
    margin-top: 10px;
    font-size: 1.3rem;
    font-weight: 600;
    color: white;
    width: 70%;
}

/////////////////////

.form-con {
    width: 50%;
    display: flex;
    justify-content: center;
}

////////////////////////////////// first col

.first-col {
    width: 40%;
}

.second-col {
    width: 60%;
    display: flex;
    justify-content: center;
}

.sub-headline-is {
    font-weight: 600;
    font-family: "Source Sans Pro";
    // , sans-serif;
    text-align: left;
    font-size: 1.1em;
    // white-space: nowrap;
}

.arrow-adjuster {
    height: 15px;
    margin-right: 10px;
}

.ul-for-signup-page {
    list-style: none;
    padding-inline-start: 2px;
    font-size: 0.9em;
}

.li-for-signuppage {
    display: flex;
    align-items: baseline;
    padding: 4px 0px;
}

///////////////////////////////////////////////////////////

.chkbox-con {
    margin-top: 10px;
    font-size: 1.4rem;

    .margin-reducer-for-p {
        margin-bottom: 0.5em;
    }
}

.name-adjuster {
    display: flex;
}

.redness {
    color: var(--color);
    font-weight: 700;
}

.checkbox-adjuster {
    display: flex;
    flex-direction: column;

    .ant-checkbox-wrapper {
        font-size: 17px !important;
        margin-inline-start: 0px !important;
    }
}

.input-font-adjuster {
    font-size: 1.1rem !important;
    border: 1px solid #d9d9d9;
    // margin-top: 10px;
    margin-bottom: 15px;
}

@media only screen and (max-width: 1150px) {
    .heading-con {
        text-align: center;
    }

    .contact-us-content-con {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .form-const {
        width: 103% !important;
        display: flex;
        justify-content: center;
    }

    .first-col {
        width: 90%;
    }

    .second-col {
        width: 90%;
    }
}

@media only screen and (max-width: 600px) {
    .content-container {
        max-width: 100vw;
    }

    .form-constz {
        .ant-form-item-control-input-content {
            // input {
            //     width: 75vw !important;
            // }

            // .ant-input-affix-wrapper {
            //     width: 75vw !important;
            // }

            .chkbox-con {
                max-width: 75vw !important;
            }
        }
    }
}

@media only screen and (max-width: 577px) {
    .heading-con {
        font-size: 45px;
    }

    .sub-headline-is {
        font-size: 1em;
    }

    .form-constz {
        .ant-form-item-control-input-content {
            input {
                // width: 75% !important;
            }

            .ant-input-affix-wrapper {
                // width: 75% !important;
            }
        }
    }

}

.other-chkbx-adjuster {
    display: flex;
    font-size: 17px !important;
}

.ant-form-item-explain-error {
    color: red;
    width: auto;
}

.chkbx-inner-adjuster {
    display: flex;
    font-size: 17px !important;
}

// .ant-form-item-explain-error {
//     color: red;
//     width: 140%;
// }

:where(.css-dev-only-do-not-override-1vtf12y).ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-inline-start: 0px !important;
}

:where(.css-dev-only-do-not-override-1vtf12y)[class^="ant-checkbox"] [class^="ant-checkbox"],
:where(.css-dev-only-do-not-override-1vtf12y)[class*=" ant-checkbox"] [class^="ant-checkbox"],
:where(.css-dev-only-do-not-override-1vtf12y)[class^="ant-checkbox"] [class*=" ant-checkbox"],
:where(.css-dev-only-do-not-override-1vtf12y)[class*=" ant-checkbox"] [class*=" ant-checkbox"] {
    margin-top: 1px !important;
}

.ant-input-suffix {
    // margin-inline-start: 75px !important;
}
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .ant-form-item-control-input {
        width: 100% !important;
    }

    :where(.css-dev-only-do-not-override-1vtf12y)[class^="ant-checkbox"] [class^="ant-checkbox"], :where(.css-dev-only-do-not-override-1vtf12y)[class*=" ant-checkbox"] [class^="ant-checkbox"], :where(.css-dev-only-do-not-override-1vtf12y)[class^="ant-checkbox"] [class*=" ant-checkbox"], :where(.css-dev-only-do-not-override-1vtf12y)[class*=" ant-checkbox"] [class*=" ant-checkbox"]{
        margin-top: 1px !important;
    }
    .main-container-create-account {
        padding: 1rem !important;
    }

    .heading-con {
        margin-top: 0px !important;
        font-size: 35px !important;
        text-align: left !important;
    }

    .contact-us-content-con {
        margin-top: 1vh !important;
    }

    .first-col {
        width: auto !important;
    }

    .second-col {
        width: -webkit-fill-available !important;
        width: -moz-available !important;
        justify-content: flex-start !important;
        margin-right: 0.3rem !important;
    }

    .form-constz {
        width: 100% !important;
    }

    .form-constz .ant-form-item {
        margin-bottom: 10px !important;
    }

    .icon-adjusting ::placeholder {
        font-size: .9rem !important;
    }

    .checkbox-adjuster .ant-checkbox-wrapper {
        margin-inline-start: 0px !important;
    }

    .checkbox-adjuster label {
        display: flex;
        align-items: center;
        line-height: 1.5;
    }

    .form-constz .ant-form-item-control-input-content .ant-input-affix-wrapper {
        // width: 75% !important;
    }

    :where(.css-dev-only-do-not-override-1vtf12y).ant-input-affix-wrapper .ant-input-suffix {
        margin-inline-start: 5rem !important;
    }

    :where(.css-1vtf12y).ant-input-affix-wrapper .ant-input-suffix {
        margin-inline-start: 5rem !important;
    }

    .form-constz .ant-form-item-control-input-content input::placeholder {
        opacity: .5;
        font-size: 1rem !important;
    }

    .form-constz .ant-form-item-control-input-content .chkbox-con {
        max-width: 92vw !important;
    }
}