/* Absolute Center Spinner */
.loading {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e4e4e4;
  top: 0;
  z-index: 999;

  .loading-text {
    margin: 0;
    z-index: 101;
    color: #005fc1;
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: visible;
    text-align: center;

    span {
      padding: 5px;
      color: rgba(0, 0, 0, 0.54);
      font-weight: 100;
    }

    svg {
      circle {
        stroke-width: 1;
        stroke: #1565c0;
      }
    }
  }

  .error {
    svg {
      circle {
        stroke: red;
      }
    }
  }

  .warning {
    svg {
      circle {
        stroke: orange;
      }
    }
  }

  .success {
    svg {
      circle {
        stroke: green;
      }
    }
  }
}

/* Transparent Overlay */
.loading::before {
  content: 'Loading...';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000010;
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
