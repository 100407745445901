:root {
  --color: #e4003b;
}

Button:hover {
  color: white;
  font-weight: 600;
}

.Home-Image-Div {
  display: flex;
  align-items: center;
  opacity: calc(100 / 100);
  background-size: contain;
  background-position: center center;
  box-sizing: border-box;
  font-size: 18px;
  color: black;
}

.m-img {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
  box-sizing: border-box;
}

.para {
  padding-top: 50px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(224, 225, 227, 1);

  margin-bottom: 124px;
  font-size: 30px;
  color: #1a1818;
  font-style: italic;
  font-family: "Source Sans Pro";
  line-height: 1.5;
}

a.body {
  color: var(--color);
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  font-family: "Source Sans Pro";
  /* , sans-serif; */
}

.para p {
  text-align: center;
}

/* .container {
   max-width: 1210px;
   padding: 0 15px;
   margin-left: 15px !important;
   margin-right: auto;
   max-width: 72.66667rem;
   padding: 0 1.5rem;
   position: relative;
   width: 100%;  
 } */

.sub-layouts-container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  z-index: 0;
  margin-bottom: 50px;
  position: relative;
}

.sub-layouts {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-basis: 50%;
  max-width: 50%;
  z-index: 0;
  justify-content: center;
  padding-right: 25px;
  padding-left: 25px;
  position: relative;
}

.fp-Title-div {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  color: black;
  line-height: 1;
  margin-bottom: 10px;
  line-height: 66px;
  padding: 20px;
}

.fp-Title {
  text-align: left;
  font-size: 60px;
  font-family: "Source Sans Pro";
  color: #1a1818;
  margin-bottom: 10px;
  line-height: 66px;
}

.fp-Title strong {
  color: #e4003b;
  font-size: 40px;
}

.fp-para {
  color: #1a1818;
  font-size: 21px;
  line-height: 25px;
  margin-bottom: 10px;
  text-align: start;
}

.fp-link a {
  color: #e4003b !important;
  font-size: 21px;
  font-weight: 700;
}

.cta-main {
  padding: 60px 0px 25px 0px;
  text-align: center;
  width: 100%;
  background-color: #edeeef;
}

.cta-title {
  font-size: 48px;
  color: white;
  line-height: 1.2;
  font-weight: 600;
}

.cta-title-2 {
  font-size: 48px;
  line-height: 1.2;
  padding-bottom: 20px;
  font-weight: 600;
}

.cta-main-bg {
  padding: 60px 0;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  background-color: var(--color);
  background-size: cover;
  background-repeat: no-repeat;
  height: 30vh;
  background: linear-gradient(rgba(228, 0, 59, 0.9), rgba(228, 0, 59, 0.9)),
    url("../../../images/GettyImages-679195147.jpg");
}

.btn-white {
  background: white;
  color: var(--color);
  font-size: 26px;
  padding: 10px 25px;
  text-transform: uppercase;
  font-weight: 700;
}

.btn-white:hover {
  background-color: black;
  color: white;
}

.Link {
  padding: 7px 10px;
  width: 43%;
  font-weight: 600;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  font-size: 21px !important;
  margin-left: 45%;
}

.anchor-for-how {
  color: var(--color);
  font-style: normal;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: 700;
}

.anchor-for-learn {
  color: var(--color);
  font-style: normal;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: 700;
}

.cta-details p {
  display: block;
  text-align: center;
  justify-content: center;
  padding-left: 1%;
  margin-left: 5%;
  width: 90%;
}

.body-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1210px;
  padding: 0 15px;
  margin: 0 auto;
}

.red-text {
  color: #e4003b;
  font-size: 60px;
  font-weight: 700;
}

.black-text {
  color: black;
  font-weight: 400;
  font-size: 60px;
}
.banner-imgs {
  width: 100%;
}

.container-f-both {
  position: relative;
  max-height: 100vh;
}

.Reg-img {
  height: 15px;
}

.free-trial-btn {
  border: none;
  font-size: 30px;
  font-weight: 600;
  border-radius: 7px;
  background-color: var(--color);
  color: white;
  position: absolute;
  left: 78%;
  bottom: 15%;
  width: 17rem;
  height: 4rem;
  font-family: "Source Sans Pro";
}

.free-trial-btn:hover {
  filter: contrast(115%);
  cursor: pointer;
}

.txt-font {
  font-size: 20px;
}

::-webkit-scrollbar-thumb {
  background: #e4003b;
  border-radius: 3px;
}

::-webkit-scrollbar {
  width: 4px;
}

:where(.css-dev-only-do-not-override-1vtf12y).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #e4003b !important;
  border-color: #e4003b !important;
}

@media (min-width: 360px) and (max-width: 389px) {
  .free-trial-btn {
    font-size: 14px !important;
    left: 55% !important;
    bottom: 10%;
    width: 8rem !important;
    height: 2rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    text-align: left !important;
    font-size: 18px !important;
  }

  .para {
    padding-top: 5%;
    padding-bottom: 0%;
    margin-bottom: 7%;
  }

  .anchor-for-how {
    font-size: 1.1rem !important;
  }

  .sub-layouts-container {
    margin-bottom: 6%;
  }

  .body-row {
    display: flex;
    flex-direction: column;
    flex-wrap: initial;
  }

  .sub-layouts {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 30px;
  }

  .black-text {
    font-size: 30px;
  }

  .red-text {
    font-size: 30px;
  }

  .fp-para {
    font-size: 18px;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .fp-link a {
    font-weight: 600;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 1.8rem;
    padding-bottom: 4%;
  }

  .cta-details p {
    width: 90% !important;
    font-size: 18px !important;
    text-align: start !important;
    line-height: 25px !important;
    margin-left: 7%;
  }

  .anchor-for-learn {
    font-size: 1.1rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 25vh;
  }

  .cta-title {
    font-size: 1.5rem;
    margin-bottom: 8%;
  }

  .btn-white {
    font-size: 1.5rem;
  }
}

@media (min-width: 390px) and (max-width: 410px) {
  .free-trial-btn {
    font-size: 14px !important;
    left: 55% !important;
    bottom: 10%;
    width: 8rem !important;
    height: 2rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    text-align: left !important;
    font-size: 20px !important;
  }

  .para {
    padding-top: 5%;
    padding-bottom: 0%;
    margin-bottom: 7%;
  }

  .anchor-for-how {
    font-size: 1.2rem !important;
  }

  .sub-layouts-container {
    margin-bottom: 6%;
  }

  .body-row {
    display: flex;
    flex-direction: column;
    flex-wrap: initial;
  }

  .sub-layouts {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 30px;
  }

  .black-text {
    font-size: 30px;
  }

  .red-text {
    font-size: 30px;
  }

  .fp-para {
    font-size: 20px;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .fp-link a {
    font-weight: 600;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 1.8rem;
  }

  .cta-details p {
    width: 90% !important;
    font-size: 20px !important;
    text-align: start !important;
    line-height: 25px !important;
    margin-left: 6%;
  }

  .anchor-for-learn {
    font-size: 1.3rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 20vh;
  }

  .cta-title {
    font-size: 1.6rem;
    margin-bottom: 9%;
  }

  .btn-white {
    font-size: 1.5rem;
  }
}

@media (min-width: 411px) and (max-width: 479px) {
  .free-trial-btn {
    font-size: 14px !important;
    bottom: 10%;
    left: 60% !important;
    width: 8rem !important;
    height: 2rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    text-align: left !important;
    font-size: 21px !important;
  }

  .para {
    padding-top: 5%;
    padding-bottom: 0%;
    margin-bottom: 7%;
  }

  .anchor-for-how {
    font-size: 1.3rem !important;
  }

  .sub-layouts-container {
    margin-bottom: 6%;
  }

  .body-row {
    display: flex;
    flex-direction: column;
    flex-wrap: initial;
  }

  .sub-layouts {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 30px;
  }

  .black-text {
    font-size: 30px;
  }

  .red-text {
    font-size: 30px;
  }

  .fp-para {
    font-size: 20px;
  }

  .fp-link a {
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 1.8rem;
  }

  .cta-details p {
    width: 90% !important;
    font-size: 20px !important;
    text-align: start !important;
    line-height: 25px !important;
    margin-left: 8%;
  }

  .anchor-for-learn {
    font-size: 1.3rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 24vh;
  }

  .cta-title {
    font-size: 1.7rem;
    margin-bottom: 8%;
  }

  .btn-white {
    font-size: 1.6rem;
  }
}

@media (min-width: 480px) and (max-width: 499px) {
  .free-trial-btn {
    font-size: 16px !important;
    bottom: 10%;
    left: 60% !important;
    width: 8.5rem !important;
    height: 2.5rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    text-align: left !important;
    font-size: 25px !important;
  }

  .para {
    padding-top: 5%;
    padding-bottom: 0%;
    margin-bottom: 7%;
  }

  .anchor-for-how {
    font-size: 1.5rem !important;
  }

  .sub-layouts-container {
    margin-bottom: 6%;
  }

  .body-row {
    display: flex;
    flex-direction: column;
    flex-wrap: initial;
  }

  .sub-layouts {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 30px;
  }

  .black-text {
    font-size: 35px;
  }

  .red-text {
    font-size: 35px;
  }

  .fp-para {
    font-size: 23px;
    line-height: 30px;
  }

  .fp-link a {
    font-size: 25px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2rem;
  }

  .cta-details p {
    width: 90% !important;
    font-size: 23px !important;
    text-align: start !important;
    line-height: 30px !important;
    margin-left: 9%;
  }

  .anchor-for-learn {
    font-size: 1.4rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 40vh;
  }

  .cta-title {
    font-size: 1.7rem;
    margin-bottom: 5%;
  }

  .btn-white {
    font-size: 1.5rem;
  }
}

@media (min-width: 500px) and (max-width: 519px) {
  .free-trial-btn {
    font-size: 16px !important;
    bottom: 10%;
    left: 60% !important;
    width: 8.5rem !important;
    height: 2.5rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    text-align: left !important;
    font-size: 25px !important;
  }

  .para {
    padding-top: 5%;
    padding-bottom: 0%;
    margin-bottom: 7%;
  }

  .anchor-for-how {
    font-size: 1.5rem !important;
  }

  .sub-layouts-container {
    margin-bottom: 6%;
  }

  .body-row {
    display: flex;
    flex-direction: column;
    flex-wrap: initial;
  }

  .sub-layouts {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 30px;
  }

  .black-text {
    font-size: 35px;
  }

  .red-text {
    font-size: 35px;
  }

  .fp-para {
    font-size: 23px;
    line-height: 30px;
  }

  .fp-link a {
    font-size: 25px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2rem;
  }

  .cta-details p {
    width: 90% !important;
    font-size: 24px !important;
    text-align: start !important;
    line-height: 30px !important;
    margin-left: 10%;
  }

  .anchor-for-learn {
    font-size: 1.4rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 20vh;
  }

  .cta-title {
    font-size: 1.7rem;
    margin-bottom: 7%;
  }

  .btn-white {
    font-size: 1.5rem;
  }
}

@media (min-width: 520px) and (max-width: 549px) {
  .free-trial-btn {
    font-size: 16px !important;
    bottom: 10%;
    left: 60% !important;
    width: 8.5rem !important;
    height: 2.5rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    text-align: left !important;
    font-size: 25px !important;
  }

  .para {
    padding-top: 5%;
    padding-bottom: 0%;
    margin-bottom: 7%;
  }

  .anchor-for-how {
    font-size: 1.5rem !important;
  }

  .sub-layouts-container {
    margin-bottom: 6%;
  }

  .body-row {
    display: flex;
    flex-direction: column;
    flex-wrap: initial;
  }

  .sub-layouts {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 30px;
  }

  .black-text {
    font-size: 35px;
  }

  .red-text {
    font-size: 35px;
  }

  .fp-para {
    font-size: 23px;
    line-height: 30px;
  }

  .fp-link a {
    font-size: 25px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2rem;
  }

  .cta-details p {
    width: 92% !important;
    font-size: 23px !important;
    line-height: 30px !important;
    margin-left: 3%;
  }

  .anchor-for-learn {
    font-size: 1.4rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 20vh;
  }

  .cta-title {
    font-size: 1.7rem;
    margin-bottom: 7%;
  }

  .btn-white {
    font-size: 1.5rem;
  }
}

@media (min-width: 550px) and (max-width: 599px) {
  .free-trial-btn {
    font-size: 16px !important;
    bottom: 10%;
    left: 60% !important;
    width: 8.5rem !important;
    height: 2.5rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    text-align: left !important;
    font-size: 25px !important;
  }

  .para {
    padding-top: 5%;
    padding-bottom: 0%;
    margin-bottom: 7%;
  }

  .anchor-for-how {
    font-size: 1.5rem !important;
  }

  .sub-layouts-container {
    margin-bottom: 6%;
  }

  .body-row {
    display: flex;
    flex-direction: column;
    flex-wrap: initial;
  }

  .sub-layouts {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 30px;
  }

  .black-text {
    font-size: 35px;
  }

  .red-text {
    font-size: 35px;
  }

  .fp-para {
    font-size: 23px;
    line-height: 30px;
  }

  .fp-link a {
    font-size: 25px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2rem;
  }

  .cta-details p {
    font-size: 23px !important;
    line-height: 33px !important;
  }

  .anchor-for-learn {
    font-size: 1.4rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 20vh;
  }

  .cta-title {
    font-size: 1.7rem;
    margin-bottom: 7%;
  }

  .btn-white {
    font-size: 1.5rem;
  }
}

@media (min-width: 600px) and (max-width: 649px) {
  .free-trial-btn {
    font-size: 18px !important;
    bottom: 10%;
    left: 60% !important;
    width: 10rem !important;
    height: 3rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    text-align: left !important;
    font-size: 28px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 7%;
  }

  .anchor-for-how {
    font-size: 1.8rem !important;
  }

  .sub-layouts-container {
    margin-bottom: 6%;
  }

  .body-row {
    display: flex;
    flex-direction: column;
    flex-wrap: initial;
  }

  .sub-layouts {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 35px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 40px;
  }

  .red-text {
    font-size: 40px;
  }

  .fp-para {
    font-size: 25px;
    line-height: 30px;
  }

  .fp-link a {
    font-size: 25px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2rem;
  }

  .cta-details p {
    width: 90% !important;
    font-size: 25px !important;
    line-height: 33px !important;
    margin-left: 5%;
  }

  .anchor-for-learn {
    font-size: 1.5rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 32vh;
  }

  .cta-title {
    font-size: 2rem;
    margin-bottom: 5%;
  }

  .btn-white {
    font-size: 1.8rem;
  }
}

@media (min-width: 650px) and (max-width: 699px) {
  .free-trial-btn {
    font-size: 18px !important;
    bottom: 10%;
    left: 60% !important;
    width: 10rem !important;
    height: 3rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    text-align: left !important;
    font-size: 28px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 7%;
  }

  .anchor-for-how {
    font-size: 1.8rem !important;
  }

  .sub-layouts-container {
    margin-bottom: 6%;
  }

  .body-row {
    display: flex;
    flex-direction: column;
    flex-wrap: initial;
  }

  .sub-layouts {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 35px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 40px;
  }

  .red-text {
    font-size: 40px;
  }

  .fp-para {
    font-size: 25px;
    line-height: 30px;
    width: 98%;
  }

  .fp-link a {
    font-size: 25px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2rem;
  }

  .cta-details p {
    font-size: 25px !important;
    line-height: 33px !important;
  }

  .anchor-for-learn {
    font-size: 1.5rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 20vh;
  }

  .cta-title {
    font-size: 2rem;
    margin-bottom: 5%;
  }

  .btn-white {
    font-size: 1.8rem;
  }
}

@media (min-width: 700px) and (max-width: 739px) {
  .free-trial-btn {
    font-size: 18px !important;
    bottom: 10%;
    left: 65% !important;
    width: 10rem !important;
    height: 3rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    text-align: left !important;
    font-size: 30px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 7%;
  }

  .anchor-for-how {
    font-size: 1.8rem !important;
  }

  .sub-layouts-container {
    margin-bottom: 6%;
  }

  .body-row {
    display: flex;
    flex-direction: column;
    flex-wrap: initial;
  }

  .sub-layouts {
    padding-left: 8%;
    padding-right: 15px;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 35px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 40px;
  }

  .red-text {
    font-size: 40px;
  }

  .fp-para {
    font-size: 25px;
    line-height: 30px;
    width: 95%;
  }

  .fp-link a {
    font-size: 25px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2rem;
  }

  .cta-details p {
    font-size: 26px !important;
    line-height: 33px !important;
  }

  .anchor-for-learn {
    font-size: 1.6rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 20vh;
  }

  .cta-title {
    font-size: 2rem;
    margin-bottom: 4%;
  }

  .btn-white {
    font-size: 1.8rem;
  }
}

@media (min-width: 740px) and (max-width: 767px) {
  .free-trial-btn {
    font-size: 18px !important;
    bottom: 10%;
    left: 65% !important;
    width: 10rem !important;
    height: 3rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 20px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 7%;
  }

  .anchor-for-how {
    font-size: 1.3rem !important;
  }

  .sub-layouts-container {
    margin-bottom: 6%;
  }

  .body-row {
    display: flex;
    flex-direction: column;
    flex-wrap: initial;
  }

  .sub-layouts {
    padding-left: 10%;
    padding-right: 15px;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 35px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 40px;
  }

  .red-text {
    font-size: 40px;
  }

  .fp-para {
    font-size: 25px;
    line-height: 30px;
    width: 92%;
  }

  .fp-link a {
    font-size: 25px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2rem;
  }

  .cta-details p {
    font-size: 24px !important;
    line-height: 33px !important;
  }

  .anchor-for-learn {
    font-size: 1.5rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 25vh;
  }

  .cta-title {
    font-size: 2rem;
    margin-bottom: 4%;
  }

  .btn-white {
    font-size: 1.8rem;
  }
}

@media (min-width: 768px) and (max-width: 799px) {
  .free-trial-btn {
    font-size: 18px !important;
    bottom: 10%;
    left: 65% !important;
    width: 10rem !important;
    height: 3rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 21px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 1.4rem !important;
  }

  .sub-layouts {
    padding-left: 0%;
    padding-right: 1%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -3%;
    line-height: 32px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 35px;
  }

  .red-text {
    font-size: 35px;
  }

  .fp-para {
    font-size: 17px;
    line-height: 25px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2rem;
  }

  .cta-details p {
    font-size: 22px !important;
    line-height: 33px !important;
  }

  .anchor-for-learn {
    font-size: 1.5rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 25vh;
  }

  .cta-title {
    font-size: 2rem;
    margin-bottom: 4%;
  }

  .btn-white {
    font-size: 1.8rem;
  }
}

@media (min-width: 800px) and (max-width: 849px) {
  .free-trial-btn {
    font-size: 18px !important;
    bottom: 10%;
    left: 65% !important;
    width: 10rem !important;
    height: 3rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 22px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 1.4rem !important;
  }

  .sub-layouts {
    padding-left: 0%;
    padding-right: 1%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -3%;
    line-height: 32px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 35px;
  }

  .red-text {
    font-size: 35px;
  }

  .fp-para {
    font-size: 17px;
    line-height: 25px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2rem;
  }

  .cta-details p {
    font-size: 22px !important;
    line-height: 33px !important;
  }

  .anchor-for-learn {
    font-size: 1.5rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 32vh;
  }

  .cta-title {
    font-size: 2rem;
    margin-bottom: 4%;
  }

  .btn-white {
    font-size: 1.8rem;
  }
}

@media (min-width: 850px) and (max-width: 899px) {
  .free-trial-btn {
    font-size: 22px !important;
    bottom: 10%;
    left: 70% !important;
    width: 12rem !important;
    height: 3rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 23px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 1.4rem !important;
  }

  .sub-layouts {
    padding-left: 0%;
    padding-right: 1%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -3%;
    line-height: 32px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 35px;
  }

  .red-text {
    font-size: 35px;
  }

  .fp-para {
    font-size: 20px;
    line-height: 25px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2rem;
  }

  .cta-details p {
    font-size: 23px !important;
    line-height: 33px !important;
  }

  .anchor-for-learn {
    font-size: 1.5rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 25vh;
  }

  .cta-title {
    font-size: 2rem;
    margin-bottom: 3%;
  }

  .btn-white {
    font-size: 1.8rem;
  }
}

@media (min-width: 900px) and (max-width: 949px) {
  .free-trial-btn {
    font-size: 22px !important;
    bottom: 10%;
    left: 70% !important;
    width: 12rem !important;
    height: 3rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 25px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 1.6rem !important;
  }

  .sub-layouts {
    padding-left: 0%;
    padding-right: 1%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 32px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 35px;
  }

  .red-text {
    font-size: 35px;
  }

  .fp-para {
    font-size: 22px;
    line-height: 28px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2rem;
  }

  .cta-details p {
    font-size: 23px !important;
    line-height: 33px !important;
  }

  .anchor-for-learn {
    font-size: 1.5rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 25vh;
  }

  .cta-title {
    font-size: 2rem;
    margin-bottom: 3%;
  }

  .btn-white {
    font-size: 1.8rem;
  }
}

@media (min-width: 950px) and (max-width: 999px) {
  .free-trial-btn {
    font-size: 22px !important;
    bottom: 10%;
    left: 70% !important;
    width: 12rem !important;
    height: 3rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 26px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 1.7rem !important;
  }

  .sub-layouts {
    padding-left: 0%;
    padding-right: 1%;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 32px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 40px;
  }

  .red-text {
    font-size: 40px;
  }

  .fp-para {
    font-size: 24px;
    line-height: 30px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2rem;
  }

  .cta-details p {
    font-size: 23px !important;
    line-height: 33px !important;
  }

  .anchor-for-learn {
    font-size: 1.5rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 25vh;
  }

  .cta-title {
    font-size: 2rem;
    margin-bottom: 3%;
  }

  .btn-white {
    font-size: 1.8rem;
  }
}

@media (min-width: 1000px) and (max-width: 1023px) {
  .free-trial-btn {
    font-size: 22px !important;
    bottom: 10%;
    left: 70% !important;
    width: 12rem !important;
    height: 3rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 26px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 1.8rem !important;
  }

  .sub-layouts {
    padding-left: 0%;
    padding-right: 1%;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 32px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 40px;
  }

  .red-text {
    font-size: 40px;
  }

  .fp-para {
    font-size: 24px;
    line-height: 30px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2rem;
  }

  .cta-details p {
    font-size: 26px !important;
    line-height: 33px !important;
  }

  .anchor-for-learn {
    font-size: 1.5rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 25vh;
  }

  .cta-title {
    font-size: 2rem;
    margin-bottom: 3%;
  }

  .btn-white {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) and (max-width: 1099px) {
  .free-trial-btn {
    font-size: 22px !important;
    bottom: 10%;
    left: 70% !important;
    width: 12rem !important;
    height: 3rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 28px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 1.8rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 32px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 40px;
  }

  .red-text {
    font-size: 40px;
  }

  .fp-para {
    font-size: 22px;
    line-height: 30px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2rem;
  }

  .cta-details p {
    font-size: 26px !important;
    line-height: 33px !important;
  }

  .anchor-for-learn {
    font-size: 1.5rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 25vh;
  }

  .cta-title {
    font-size: 2rem;
    margin-bottom: 3%;
  }

  .btn-white {
    font-size: 1.8rem;
  }
}

@media (min-width: 1100px) and (max-width: 1151px) {
  .free-trial-btn {
    font-size: 22px !important;
    bottom: 10%;
    left: 70% !important;
    width: 12rem !important;
    height: 3rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 28px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 1.8rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 40px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 45px;
  }

  .red-text {
    font-size: 45px;
  }

  .fp-para {
    font-size: 24px;
    line-height: 30px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2rem;
  }

  .cta-details p {
    font-size: 24px !important;
    line-height: 33px !important;
  }

  .anchor-for-learn {
    font-size: 1.6rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 25vh;
  }

  .cta-title {
    font-size: 2rem;
    margin-bottom: 3%;
  }

  .btn-white {
    font-size: 1.8rem;
  }
}

@media (min-width: 1152px) and (max-width: 1199px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 40px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 45px;
  }

  .red-text {
    font-size: 45px;
  }

  .fp-para {
    font-size: 26px;
    line-height: 32px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 25px !important;
    line-height: 33px !important;
  }

  .anchor-for-learn {
    font-size: 1.7rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 25vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 3%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1200px) and (max-width: 1249px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 26px !important;
    line-height: 33px !important;
  }

  .anchor-for-learn {
    font-size: 1.7rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 25vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 3%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1250px) and (max-width: 1279px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 26px !important;
    line-height: 33px !important;
  }

  .anchor-for-learn {
    font-size: 1.8rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 25vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 3%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1280px) and (max-width: 1299px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 5%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 26px !important;
    line-height: 35px !important;
  }

  .anchor-for-learn {
    font-size: 1.6rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 40vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 3%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1300px) and (max-width: 1359px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 26px !important;
    line-height: 35px !important;
  }

  .anchor-for-learn {
    font-size: 1.7rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 32vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 3%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1360px) and (max-width: 1365px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 4%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 28px !important;
    line-height: 35px !important;
  }

  .anchor-for-learn {
    font-size: 1.8rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 30vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 3%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1366px) and (max-width: 1399px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 4%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 28px !important;
    line-height: 35px !important;
  }

  .anchor-for-learn {
    font-size: 1.8rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 30vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 3%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1400px) and (max-width: 1439px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 28px !important;
    line-height: 35px !important;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 22vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 3%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1440px) and (max-width: 1449px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 28px !important;
    line-height: 35px !important;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 24vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1450px) and (max-width: 1499px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 28px !important;
    line-height: 35px !important;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 24vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1500px) and (max-width: 1549px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 28px !important;
    line-height: 35px !important;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 24vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1550px) and (max-width: 1599px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 28px !important;
    line-height: 35px !important;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 24vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1600px) and (max-width: 1649px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 28px !important;
    line-height: 35px !important;
    width: 75%;
    margin-left: 12%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 24vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1650px) and (max-width: 1679px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 28px !important;
    line-height: 35px !important;
    width: 75%;
    margin-left: 12%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 24vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1680px) and (max-width: 1699px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 75% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 25px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 28px !important;
    line-height: 35px !important;
    width: 75%;
    margin-left: 12%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 24vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1700px) and (max-width: 1749px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 28px !important;
    line-height: 35px !important;
    width: 75%;
    margin-left: 12%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 24vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1750px) and (max-width: 1799px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 28px !important;
    line-height: 35px !important;
    width: 70%;
    margin-left: 15%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 24vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1800px) and (max-width: 1849px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 28px !important;
    line-height: 35px !important;
    width: 70%;
    margin-left: 15%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 24vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1850px) and (max-width: 1899px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 28px !important;
    line-height: 35px !important;
    width: 70%;
    margin-left: 15%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 24vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1900px) and (max-width: 1919px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 10%;
    left: 70% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 32px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2rem !important;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    margin-top: -2%;
    line-height: 45px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 50px;
  }

  .red-text {
    font-size: 50px;
  }

  .fp-para {
    font-size: 28px;
    line-height: 34px;
    width: 92%;
  }

  .fp-link a {
    font-size: 22px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 2.5rem;
  }

  .cta-details p {
    font-size: 30px !important;
    line-height: 35px !important;
    width: 70%;
    margin-left: 15%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 24vh;
  }

  .cta-title {
    font-size: 2.5rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.2rem;
  }
}

@media (min-width: 1920px) and (max-width: 1949px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 15%;
    left: 80% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 40px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2.5rem !important;
  }

  .body-row {
    max-width: 80%;
    margin: 0% 10%;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 60px;
    margin-bottom: 3%;
  }

  .fp-para {
    font-size: 30px;
    line-height: 40px;
    width: 100%;
  }

  .fp-link a {
    font-size: 28px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 3rem;
  }

  .cta-details p {
    font-size: 30px !important;
    line-height: 35px !important;
    width: 80%;
    margin-left: 10%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 22vh;
  }

  .cta-title {
    font-size: 3rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.5rem;
  }
}

@media (min-width: 1950px) and (max-width: 1999px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 15%;
    left: 80% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 40px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2.5rem !important;
  }

  .body-row {
    max-width: 80%;
    margin: 0% 10%;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 60px;
    margin-bottom: 3%;
  }

  .fp-para {
    font-size: 30px;
    line-height: 40px;
    width: 100%;
  }

  .fp-link a {
    font-size: 28px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 3rem;
  }

  .cta-details p {
    font-size: 30px !important;
    line-height: 35px !important;
    width: 80%;
    margin-left: 10%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 22vh;
  }

  .cta-title {
    font-size: 3rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.5rem;
  }
}

@media (min-width: 2000px) and (max-width: 2047px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 15%;
    left: 80% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 40px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2.5rem !important;
  }

  .body-row {
    max-width: 80%;
    margin: 0% 10%;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 60px;
    margin-bottom: 3%;
  }

  .fp-para {
    font-size: 30px;
    line-height: 40px;
    width: 100%;
  }

  .fp-link a {
    font-size: 28px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 3rem;
  }

  .cta-details p {
    font-size: 30px !important;
    line-height: 35px !important;
    width: 80%;
    margin-left: 10%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 22vh;
  }

  .cta-title {
    font-size: 3rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.5rem;
  }
}

@media (min-width: 2048px) and (max-width: 2099px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 15%;
    left: 80% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 40px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2.5rem !important;
  }

  .body-row {
    max-width: 80%;
    margin: 0% 10%;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 60px;
    margin-bottom: 3%;
  }

  .fp-para {
    font-size: 30px;
    line-height: 40px;
    width: 100%;
  }

  .fp-link a {
    font-size: 28px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 3rem;
  }

  .cta-details p {
    font-size: 30px !important;
    line-height: 35px !important;
    width: 80%;
    margin-left: 10%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 22vh;
  }

  .cta-title {
    font-size: 3rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.5rem;
  }
}

@media (min-width: 2100px) and (max-width: 2199px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 15%;
    left: 80% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 40px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2.5rem !important;
  }

  .body-row {
    max-width: 80%;
    margin: 0% 10%;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 60px;
    margin-bottom: 3%;
  }

  .fp-para {
    font-size: 30px;
    line-height: 40px;
    width: 100%;
  }

  .fp-link a {
    font-size: 28px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 3rem;
  }

  .cta-details p {
    font-size: 30px !important;
    line-height: 35px !important;
    width: 80%;
    margin-left: 10%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 22vh;
  }

  .cta-title {
    font-size: 3rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.5rem;
  }
}

@media (min-width: 2200px) and (max-width: 2299px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 15%;
    left: 80% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 40px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2.5rem !important;
  }

  .body-row {
    max-width: 80%;
    margin: 0% 10%;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 60px;
    margin-bottom: 3%;
  }

  .fp-para {
    font-size: 30px;
    line-height: 40px;
    width: 100%;
  }

  .fp-link a {
    font-size: 28px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 3rem;
  }

  .cta-details p {
    font-size: 30px !important;
    line-height: 35px !important;
    width: 80%;
    margin-left: 10%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 22vh;
  }

  .cta-title {
    font-size: 3rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.5rem;
  }
}

@media (min-width: 2300px) and (max-width: 2399px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 15%;
    left: 80% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 40px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2.5rem !important;
  }

  .body-row {
    max-width: 80%;
    margin: 0% 10%;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 60px;
    margin-bottom: 3%;
  }

  .fp-para {
    font-size: 30px;
    line-height: 40px;
    width: 100%;
  }

  .fp-link a {
    font-size: 28px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 3rem;
  }

  .cta-details p {
    font-size: 30px !important;
    line-height: 35px !important;
    width: 80%;
    margin-left: 10%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 22vh;
  }

  .cta-title {
    font-size: 3rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.5rem;
  }
}

@media (min-width: 2400px) and (max-width: 2499px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 15%;
    left: 80% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 40px !important;
  }

  .para {
    padding-top: 4%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2.5rem !important;
  }

  .body-row {
    max-width: 80%;
    margin: 0% 10%;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 60px;
    margin-bottom: 3%;
  }

  .fp-para {
    font-size: 30px;
    line-height: 40px;
    width: 100%;
  }

  .fp-link a {
    font-size: 28px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 3rem;
  }

  .cta-details p {
    font-size: 30px !important;
    line-height: 35px !important;
    width: 80%;
    margin-left: 10%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 22vh;
  }

  .cta-title {
    font-size: 3rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.5rem;
  }
}

@media (min-width: 2500px) and (max-width: 2559px) {
  .free-trial-btn {
    font-size: 30px !important;
    bottom: 15%;
    left: 80% !important;
    width: 16rem !important;
    height: 4rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 40px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 2.5rem !important;
  }

  .body-row {
    max-width: 80%;
    margin: 0% 10%;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 60px;
    margin-bottom: 3%;
  }

  .fp-para {
    font-size: 30px;
    line-height: 40px;
    width: 100%;
  }

  .fp-link a {
    font-size: 28px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 3rem;
  }

  .cta-details p {
    font-size: 30px !important;
    line-height: 35px !important;
    width: 80%;
    margin-left: 10%;
  }

  .anchor-for-learn {
    font-size: 1.9rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 22vh;
  }

  .cta-title {
    font-size: 3rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 2.5rem;
  }
}

@media (min-width: 2560px) and (max-width: 2879px) {
  .free-trial-btn {
    font-size: 35px !important;
    bottom: 15%;
    left: 80% !important;
    width: 20rem !important;
    height: 5rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 50px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 3rem !important;
  }

  .body-row {
    max-width: 80%;
    margin: 0% 10%;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 65px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 70px;
  }

  .red-text {
    font-size: 70px;
  }

  .fp-para {
    font-size: 38px;
    line-height: 50px;
    width: 100%;
  }

  .fp-link a {
    font-size: 35px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 3.5rem;
  }

  .cta-details p {
    font-size: 35px !important;
    line-height: 40px !important;
    width: 75%;
    margin-left: 12%;
  }

  .anchor-for-learn {
    font-size: 2.2rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 22vh;
  }

  .cta-title {
    font-size: 3.5rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 3.5rem;
  }
}

@media (min-width: 2880px) and (max-width: 3439px) {
  .free-trial-btn {
    font-size: 35px !important;
    bottom: 15%;
    left: 80% !important;
    width: 20rem !important;
    height: 5rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 50px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 3rem !important;
  }

  .body-row {
    max-width: 80%;
    margin: 0% 10%;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 65px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 70px;
  }

  .red-text {
    font-size: 70px;
  }

  .fp-para {
    font-size: 38px;
    line-height: 50px;
    width: 100%;
  }

  .fp-link a {
    font-size: 35px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 3.5rem;
  }

  .cta-details p {
    font-size: 35px !important;
    line-height: 40px !important;
    width: 75%;
    margin-left: 12%;
  }

  .anchor-for-learn {
    font-size: 2.2rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 22vh;
  }

  .cta-title {
    font-size: 3.5rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 3.5rem;
  }
}

@media (min-width: 3440px) and (max-width: 3839px) {
  .free-trial-btn {
    font-size: 35px !important;
    bottom: 15%;
    left: 80% !important;
    width: 20rem !important;
    height: 5rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 50px !important;
  }

  .para {
    padding-top: 7%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 3.2rem !important;
  }

  .body-row {
    max-width: 80%;
    margin: 0% 10%;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 65px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 70px;
  }

  .red-text {
    font-size: 70px;
  }

  .fp-para {
    font-size: 38px;
    line-height: 50px;
    width: 100%;
  }

  .fp-link a {
    font-size: 35px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 3.5rem;
  }

  .cta-details p {
    font-size: 35px !important;
    line-height: 40px !important;
    width: 75%;
    margin-left: 12%;
  }

  .anchor-for-learn {
    font-size: 2.2rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 22vh;
  }

  .cta-title {
    font-size: 3.5rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 3.5rem;
  }
}

@media (min-width: 3840px) {
  .free-trial-btn {
    font-size: 35px !important;
    bottom: 15%;
    left: 80% !important;
    width: 20rem !important;
    height: 5rem !important;
  }

  .para p {
    padding: 0 1rem 0 1rem !important;
    font-size: 50px !important;
  }

  .para {
    padding-top: 2%;
    padding-bottom: 0%;
    margin-bottom: 4%;
  }

  .anchor-for-how {
    font-size: 3rem !important;
  }

  .body-row {
    max-width: 80%;
    margin: 0% 10%;
  }

  .sub-layouts {
    padding-left: 3%;
    padding-right: 3%;
    max-width: 100%;
  }

  .fp-Title {
    line-height: 65px;
    margin-bottom: 3%;
  }

  .black-text {
    font-size: 70px;
  }

  .red-text {
    font-size: 70px;
  }

  .fp-para {
    font-size: 38px;
    line-height: 50px;
    width: 100%;
  }

  .fp-link a {
    font-size: 35px;
    font-weight: 600;
  }

  .fp-link {
    margin-bottom: 2%;
  }

  .cta-main {
    padding: 20px 0px 10px 0px;
  }

  .cta-title-2 {
    font-size: 3.5rem;
  }

  .cta-details p {
    font-size: 35px !important;
    line-height: 40px !important;
    width: 75%;
    margin-left: 12%;
  }

  .anchor-for-learn {
    font-size: 2.2rem !important;
  }

  .cta-main-bg {
    padding: 20px 0;
    height: 22vh;
  }

  .cta-title {
    font-size: 3.5rem;
    margin-bottom: 2%;
  }

  .btn-white {
    font-size: 3.5rem;
  }
}

:where(.css-1m62vyb).ant-float-btn-default .ant-float-btn-body{
  background-color: #e4003b !important;
}