.main-container-detail {
  .staticheading {
    font-size: 54px;
    letter-spacing: 0.1rem;
    margin-top: 6vh;
    margin-left: 11vw;
  }
  .about {
    color: var(--color);
    font-weight: 700;
  }
  .us {
    color: #1a1818;
  }
  .cell-ninja-color {
    color: var(--color);
    font-weight: 700;
  }
  .m-con {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8vh;
    margin-bottom: 18vh;
  }
  .left-right-con {
    max-width: 75vw;
    display: flex;
  }
  .leftie {
    max-width: 50%;
    padding-right: 10px;
  }
  .rightie {
    max-width: 50%;
  }
  .number {
    color: var(--color);
    font-weight: 700;
    font-size: 3.1rem;
  }
  .descrip {
    font-size: 1.4rem;
    line-height: 1.5em;
    margin-left: 0px;
    color: #1a1818;
    font-weight: 600;
  }
  .point {
    width: 37vw;
    display: flex;
    align-items: center;
  }
  .find-relevant-fact {
    margin-top: 3vh;
  }
  .frf-span {
    font-size: 1.4rem;
    color: #1a1818;
    font-weight: 400;
  }
  .darker-span {
    font-size: 1.4rem;
    color: #1a1818;
    font-weight: 600;
  }
  .img-how-it-works {
    margin-left: 16px;
    width: 36.3vw;
    border: 1px solid #818386;
  }

  .facts-container {
    padding: 15px;
    background-color: var(--color);
    color: white;
    display: flex;
    flex-direction: column;
    padding-left: 26px;
    margin-left: 16px;
    margin-top: 4vh;
  }
  .facts {
    font-size: 30px;
    font-weight: 700;
  }
  .people-span {
    font-size: 1.6em;
    line-height: 2.1rem;
  }

  .ul-for-accurate {
    padding-left: 20px;
    font-size: 1.4rem;
    color: #1a1818;
    font-weight: 700;
    line-height: 2.5rem;
  }
  .span-understand {
    color: var(--color);
    font-size: 1.6rem;
    font-weight: 700;
  }

  .sec-portion {
    margin-top: 3vh;
  }
  .color-adjuster {
    color: var(--color);
    font-weight: bold;
  }
  .ul-of-report {
    list-style: style decimal;
  }
  .divider {
    padding: 15px 0px;
    margin-left: 16px;
    width: 36.3vw;
    color: #818386;
    font-size: 1.3rem;
    font-weight: 500;
  }
}
@media only screen and (max-width: 700px) {
  .main-container-detail {
    .divider {
      width: auto;
    }
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .main-container-detail{
    padding: 1rem !important;
  }
  .main-container-detail .m-con{
    margin-top: 0vh !important;
    margin-bottom: 0px !important;
  }
  .main-container-detail .leftie{
    padding-right: 0px !important;
  }
  .main-container-detail .find-relevant-fact{
    margin-top: 2vh !important;
  }
  .main-container-detail .divider{
    margin-left: 0px !important;
  }
}

