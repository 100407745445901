.custom-switch .ant-switch-checked {
  background-color: blue !important;
  width: 8vw;
  margin-left: 5px;
}

.custom-switch .ant-switch {
  background-color: red;
  width: 8vw;
  margin-left: 5px;
}

.custom-switch .ant-switch-checked .ant-switch-handle {
  width: 100;
  height: 88%;
  left: calc(100% - 10px - 2px);
}

.custom-switch .ant-switch-handle::before {
  height: 28px;
  right: 8px !important;
  border-radius: 3px !important;
}

.custom-switch .ant-switch-inner {
  margin: 5% 0 0 25% !important;
}

.add-30-days-btn {
  margin-left: 3%;
}

.add-30-days-btn:hover {
  border-color: #e4003b !important;
  color: #e4003b !important;
}