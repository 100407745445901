.main-reset-container {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100vh;
}
.sub-con-reset {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-top: 2rem;
}

.reset-span {
    font-size: 3.2rem;
    color: #1a1818;
    letter-spacing: 2px;
    
}
.second {
    p {
        text-align: start;
        padding-left: 0%;
    }
    margin-top: 3vh;
}
.fillin-span {
    font-size: 1.3rem;
    text-align: justify !important;
}
.third {
    margin-top: 3vh;
    .ant-picker-input input::placeholder {
        color: black;
      }
      ::placeholder {
        color: #09090977;
        opacity: 1; /* Firefox */
        font-size: 1.3rem;
    }
    .ant-form {
        margin-left: -6px;
    }
    .ant-form-item-label{
        display: flex;
    align-items: center;
    }
    .ant-form-item-label > label {
        font-size: 20px;
    }
}
.email-span {
    font-size: 1.2rem;
    color: black;
}
.inner-con {
    display: flex;
}
.form-reset {
    margin-top: 1vh;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.input-reset {
    border: 1px solid #cccccc;
    padding: 12px 10px;
    width: 382px;
}
.reset-btn {
    background-color: var(--color);
    border: none;
    color: white;
    font-size: 1.1rem;
    font-weight: 700;
    height: 48px;
   
}
.form-adjuster-for-reset{
    display: flex;
    flex-direction: column;
}
.reset-btn:hover {
    background-color: var(--color);
    border: none;
    color: white;
    font-size: 1.1rem;
    font-weight: 700;
    height: 48px;
    
}
.reddish{
    color: #e4003b;
    font-weight: 700;
}

.temp{
    .ant-modal-confirm-content {
        margin-left: 40px !important;
        margin-top: 30px !important;
    }
    .ant-btn-primary {
        
    border-color: #e4003b;
    background: #e4003b;
    }
     .ant-modal-confirm-btns {
        margin-top: 0px !important;
    }
}

@media (min-width: 300px) and (max-width: 575px) {
    .third {
        .ant-form-item{
            margin-bottom: 0px !important;
        }
    }
    .reset-span{
        font-size: 35px !important;
    }
    .fillin-span{
        font-size: 22px !important;
        text-align: unset !important;
    }
    .main-reset-container{
        padding: 1rem !important;
    }
    .sub-con-reset{
        width: 90% !important;
    }
    .third{
        margin-left: 5px !important;
    }
    
    .fields{
        width: 50% !important;
    }
}

@media (min-width: 575px) and (max-width: 720px) {
    .third {
        .ant-form-item{
            margin-bottom: 0px !important;
        }
    }
    .reset-span{
        font-size: 35px !important;
    }
    .fillin-span{
        font-size: 22px !important;
        text-align: unset !important;
    }
    .main-reset-container{
        padding: 1rem !important;
    }
    .sub-con-reset{
        width: 90% !important;
    }
}
  
@media (min-width: 720px) and (max-width: 1024px) {
    .sub-con-reset {
        width: 50% !important;
    }
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .sub-con-reset {
        width: 50% !important;
    }
}
