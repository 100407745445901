.main-container-accurate{

.staticheading{
    font-size: 54px;
    letter-spacing: 0.1rem;
    margin-top: 6vh;
    margin-left: 11vw;
}
.about{
    color: var(--color);
    font-weight: 700;
    }



.m-con{
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8vh;
    margin-bottom: 18vh;
}    
.left-right-con{
    max-width: 75vw;
    display: flex;
}
.leftie{
    max-width: 50%;

}
.rightie{
    max-width: 50%;
    
}
.number{
    color: var(--color);
    font-weight: 700;
    font-size: 3.1rem;
}
.descrip{
    font-size: 1.4rem;
    line-height: 1.5em;
    margin-left: 0px;
    color: #1a1818;
    font-weight: 600;
}
.point{
    display: flex;
    align-items: center;
}
.find-relevant-fact{
margin-top: 3vh;
}
.frf-span{
 font-size: 1.4rem;
 color: #1a1818;
 font-weight: 400;

}
.img-how-it-works{
    margin-left: 16px;
    width: 36.3vw;
}

.facts-container{
    padding: 15px;
    background-color: var(--color);
    color: white;
    display: flex;
    flex-direction: column;
    padding-left: 26px;
    margin-left: 16px;
    margin-top: 4vh;
    }
.facts{
        font-size: 30px;
        font-weight: 700;
    }
.people-span{
    font-size: 1.6em;
line-height: 2.1rem;
}


.ul-for-accurate{
    padding-left:20px;
    font-size: 1.4rem;
    color: #1a1818;
    font-weight: 700;
    line-height: 2.5rem;
}
.span-understand{
    color: var(--color);
    font-size: 1.6rem;
    font-weight: 700;
}




}


@media only screen and (max-width : 700px){
    .left-right-con{
      display: block !important;
    }
    .leftie{
        max-width: 100% !important;
    }
   .rightie{
       max-width: 100% !important;
   }
   .img-how-it-works{
    margin-left: 16px;
    width: 100% !important;
    margin-top: 3vh;
}
  }

  /* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .main-container-accurate{
        padding: 1rem !important;
    }
  .staticheading{
    font-size: 35px !important;
    text-align: left !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
  .main-container-accurate .m-con{
    margin-top: 1vh !important;
    margin-bottom: 0px !important;
  }
  .main-container-accurate .left-right-con{
    max-width: none !important;
  }
  .ul-for-accurate{
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
  }
  .main-container-accurate .img-how-it-works{
    margin-left: 0 !important;
  }
}