.stepper-header {
	margin-top: 1%;
	display: flex;
}

.stepper-style {
	margin-top: 3%;
}

.steps-action Button {
	margin: 1%;
}
.json-tree-div {
	margin-left: 20%;
}

.ant-modal-content {
	border-radius: 15px !important;
}

.ant-card ant-card-bordered {
	border-left-width: 11px;
	border-left-color: #328bce;
}

.ant-modal-header {
	border-radius: 15px !important;
}
.divider-style {
	background-color: #1890ff !important;
}

.project-main-card {
	width: 98%;
	background: #328bce 0% 0% no-repeat padding-box;
	background: var(--unnamed-color-328bce) 0% 0% no-repeat padding-box;
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	height: auto !important;
	border-left-width: 11px;
	border-left-color: #2980b9;
	margin-top: 10px;
	&:hover {
		border-top-color: #2980b9;
		border-right-color: #2980b9;
		border-bottom-color: #2980b9;
		border-top-width: 1px;
		border-right-width: 1px;
		border-bottom-width: 1px;
	}
}
.heading {
	font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
		var(--unnamed-font-size-12) / var(--unnamed-line-spacing-20)
		var(--unnamed-font-family-source-sans-pro);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--gray-6);
	text-align: left;
	font: normal normal 600 11px/20px Source Sans Pro;
	letter-spacing: 0px;
	color: #8d8787;
	opacity: 1;
	margin-bottom: 5px;
}
.project-name-para {
	margin-right: 30%;
	float: left;
	clear: left;
	font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
		var(--unnamed-font-size-14) / var(--unnamed-line-spacing-22)
		var(--unnamed-font-family-source-sans-pro);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--gray-9);
	text-align: left;
	font: normal normal 600 13px/22px Source Sans Pro;
	letter-spacing: 0px;
	color: #262626;
	opacity: 1;
}
.ant-popover-inner-content {
	padding: 0px !important;
}
.source-report-div {
	color: black;
	font-size: 14px;
	float: left;
	margin: 3px 3px 0px 3px;
	clear: left;
}
.source-report-tag-div {
	float: left;
	clear: left;
	font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
		var(--unnamed-font-size-14) / var(--unnamed-line-spacing-22)
		var(--unnamed-font-family-source-sans-pro);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--gray-9);
	text-align: left;
	font: normal normal 600 13px/22px Source Sans Pro;
	letter-spacing: 0px;
	color: #262626;
	opacity: 1;
}

.ant-tag {
	margin: 1px;
}
.summary-heading-div {
	color: black;
	font-size: 14px;
	float: left;
	clear: left;
	width: 30%;
}
.summary {
	display: flex;
	flex-wrap: wrap;
	font-size: 14px;
	float: left;
	clear: left;
}
.buttons-div {
	display: flex;
	flex-wrap: wrap;
	color: black;
	font-size: 14px;
	float: right;
	margin: 0px;
	clear: right;
	margin-top: 5px;
	width: 20%;
}
.ant-button {
	vertical-align: top;
	margin: 5px;
}
.ant-button:hover {
	border-color: #2980b9;
}

.delete-project-ant-btn {
	border-color: #e4003b;
	background: #e4003b;
	vertical-align: top;
	margin: 5px;
}

.delete-project-ant-btn:hover {
	border-color: #e4003b;
	background: #e4003b;
}

.ant-card-body {
	height: auto !important;
	padding-bottom: 0px !important;
	padding: 16px;
}
.ant-modal-content {
	min-width: 44%;
}
.ant-modal-footer {
	padding-bottom: 15px;
}
.ant-tabs-content {
	overflow: scroll;
	overflow-x: hidden;
	padding: 0px 5px;
	height: 71vh;
}
@media (max-width: 768px),
	(max-width: 1024px),
	(max-width: 1200px),
	(max-width: 375px) {
	.divider-style {
		height: 0.01vh !important;
		width: 99%;
	}
	.ant-modal-content {
		width: 70%;
	}
}

.ant-modal-body {
	padding-top: 8px !important;
}

.table {
	width: 100%;
	text-align: left;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
}

.table-main-heading {
	padding: 0.75rem;
	border-bottom: 1px solid #2980b9;
	font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
		var(--unnamed-font-size-14) / var(--unnamed-line-spacing-20)
		var(--unnamed-font-family-source-sans-pro);
	letter-spacing: var(--unnamed-character-spacing-0);
	text-align: left;
	font: normal normal 600 13px/22px Source Sans Pro;
	letter-spacing: 0;
	color: white;
	opacity: 1;
	background: #2980b9;
	min-width: 80px;
}

.table-row-group {
	padding: 6px;
	color: white;
	background: #2980b9;
	font: normal normal 600 12px/22px Source Sans Pro;
}

.table-data {
	color: #262626;
	white-space: break-spaces;
	border-width: 1px;
	padding: 6px;
	font: normal normal 600 12px/22px Source Sans Pro;
	min-width: 80px;
	border-bottom: 1px solid #2980b9;
}

.ant-table-cell {
	padding: 6px !important;
	font: normal normal 600 12px/22px Source Sans Pro;
}

.editable-cell {
	position: relative;
}

.editable-cell-value-wrap {
	padding: 5px 12px;
	cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
	padding: 4px 11px;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
	border: 1px solid #434343;
}

.editable-row .ant-form-item-explain {
	position: absolute;
	top: 100%;
	font-size: 12px;
}

.ant-table-tbody > tr > td {
	font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
		var(--unnamed-font-size-149) / var(--unnamed-line-spacing-22)
		var(--unnamed-font-family-source-sans-pro);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--gray-9);
	text-align: center;
	font: normal normal 600 12px/22px Source Sans Pro;
	letter-spacing: 0px;
	color: #262626;
	opacity: 1;
	min-width: 80px;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
	position: relative;
	padding: 0px 16px;
	overflow-wrap: break-word !important;
	text-overflow: unset !important;
	min-width: 80px;
}

.ant-table-thead > tr > th {
	font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
		var(--unnamed-font-size-14) / var(--unnamed-line-spacing-20)
		var(--unnamed-font-family-source-sans-pro);
	letter-spacing: var(--unnamed-character-spacing-0);
	text-align: center;
	font: normal normal 600 12px/22px Source Sans Pro;
	letter-spacing: 0px;
	color: #095997;
	opacity: 1;
	background: white;
	min-width: 80px;
}
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
	padding: 12px 8px;
	min-width: 80px;
}

.ant-table-cell {
	padding: 6px !important;
	font: normal normal 600 12px/22px Source Sans Pro;
}

.table-striped-rows tr:nth-child(2n) td {
	background: #e0f5ff;
	border-color: #328bce;
	border-width: 1px;
	padding: 6px;
	font: normal normal 600 12px/22px Source Sans Pro;
	min-width: 80px;
}

.table-striped-rows tr td {
	border-color: #2980b9;
	border-width: 1px;
	padding: 6px;
	font: normal normal 600 12px/22px Source Sans Pro;
	min-width: 80px;
}

.table-striped-rows
	.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> thead {
	tr:not(:last-child) > th {
		border-bottom-color: #2980b9;
	}
	tr > th {
		border-bottom-color: #2980b9;
	}
}

.ant-form-item {
	margin: 16px 0px !important;
}

.ant-row {
	flex-wrap: nowrap;
}
.FormRow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 15px;
	border-top: 1px solid #ffff;
}
.FormRowLabel {
	width: 15%;
	min-width: 70px;
	padding: 11px 0;
	color: black;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	border: transparent;
}
.FormRowInput {
	font-size: 16px;
	width: 100%;
	color: black;
	background-color: transparent;
	animation: 1ms void-animation-out;
}
.FormRowInput::placeholder {
	color: #939394;
}
.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}
.SubmitButton {
	display: block;
	font-size: 16px;
	width: calc(100% - 30px);
	height: 40px;
	margin: 40px 15px 0;
	background-color: #1890ff;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
		inset 0 1px 0 #1890ff;
	border-radius: 4px;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	transition: all 100ms ease-in-out;
	will-change: transform, background-color, box-shadow;
}
.SubmitButton:active {
	background-color: #1890ff;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
		inset 0 1px 0 #1890ff;
	transform: scale(0.99);
}
.SubmitButton:disabled {
	opacity: 0.5;
	cursor: default;
	background-color: #7795f8;
	box-shadow: none;
}
.ErrorMessage {
	color: #fff;
	position: absolute;
	display: flex;
	justify-content: center;
	padding: 0 15px;
	font-size: 13px;
	margin-top: 0px;
	width: 100%;
	transform: translateY(-15px);
	opacity: 0;
	animation: fade 150ms ease-out;
	animation-delay: 50ms;
	animation-fill-mode: forwards;
	will-change: opacity, transform;
}
.Result {
	margin-top: 50px;
	text-align: center;
	animation: fade 200ms ease-out;
}

.ResultTitle {
	color: #fff;
	font-weight: 500;
	margin-bottom: 8px;
	font-size: 17px;
	text-align: center;
}

.ResultMessage {
	color: black;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 25px;
	line-height: 1.6em;
	text-align: center;
}

.ResetButton {
	border: 0;
	cursor: pointer;
	color: #095997;
}

.strip-custom-class span {
	background-image: none !important;
}

.strip-custom-class button {
	background: #2980b9 !important;
}

.ant-btn-icon-only:hover {
	color: #2980b9 !important;
}

.ant-btn-icon-only:active {
	border-color: #2980b9 !important;
}

.ant-spin-dot-item {
	background-color: #e4003b;
}

.login-form-clear-button:hover {
	border-color: #e4003b !important;
	color: #e4003b !important;
}

.login-form-apply-button {
	background-color: #e4003b;
	color: white;
}

.login-form-apply-button:hover {
	background-color: #e4003b;
	color: white;
}

.date-time-picker:hover {
	border-color: #e4003b !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: #e4003b;
	border-color: #e4003b;
}

.ant-checkbox-input{
	border-color: #e4003b;
}

.cdr-type-filter-checkbox:hover {
	border-color: #e4003b !important;
}
