:root {
  --borderColor: 1px solid #dadada;
}

.main-con-four {
  display: flex;
  flex-direction: column;
  margin-top: 1%;
}

.content-cont-four {
  min-width: 80%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.mr-four {
  margin-right: 20px;
}

.big-head-normal-four {
  font-size: 1.7rem;
  font-weight: 700;
}

.big-head-spec-four {
  font-size: 2rem;
}

.h-r-one {
  padding: 5px;
  width: 46rem;
  min-width: 46rem;
  max-width: 50rem;
  border-bottom: 1px solid #dadada;
}

.tab-head-norm {
  font-size: 1.3rem;
  font-weight: 700;
}

.tab-head-spec {
  font-size: 1.5rem;
  font-weight: 700;
}

.r-digit {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 0.5;
  padding-bottom: 12px;
}

.h-r-two {
  display: flex;
  flex-direction: column;
  border-left: 1px solid;
  border-top: 1px solid;
  width: 10rem;
  border-bottom: 1px solid;
  align-items: center;
}

.h-r-three {
  display: flex;
  flex-direction: column;
  border-top: 1px solid;
  width: 10rem;
  border-bottom: 1px solid;
  align-items: center;
}

.h-r-four {
  display: flex;
  flex-direction: column;
  border-top: 1px solid;
  border-right: 1px solid;
  width: 10rem;
  border-bottom: 1px solid;
  align-items: center;
}

.header-four {
  display: flex;
  margin-top: 20px;
}

.desc-col-four {
  font-weight: 600;
  font-size: 1.2rem;
  border: 1px solid #dadada;
  border-right: 0px;
  padding-left: 3px;
  min-width: 40rem;
  border-top: 0px;

  @media only screen and (max-width: 1024px) {
    min-width: 30rem;
    width: -webkit-fill-available;
    font-size: 1rem;
  }
}

.bold-none {
  font-weight: normal;
}

.rover {
  display: flex;
  height: 2rem;
}

.rover-row {
  display: flex;
  justify-content: flex-end;
  height: 2rem;
}

.tik {
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.ninjapro {
  background-color: #bfbfbf;
  font-weight: 900;
}

.ninjaprem {
  background-color: #d9d9d9;
  font-weight: 900;
}

.ninjabasic {
  background-color: #f2f2f2;
  font-weight: 900;
}

.full-bor {
  border-bottom: 1px solid #dadada;
  border-left: 1px solid #dadada;
}

.red-s {
  color: #fa0000;
}

.low-font {
  font-size: 1rem;
}

.midliner {
  display: flex;
  justify-content: center;
}

.mt {
  margin-top: 0px !important;
}

.radio {
  font-size: 14px !important;
}

.colred {
  color: red;
}

.ant-radio-inner::after {
  background-color: red;
}

.ant-radio-checked .ant-radio-inner {
  border-color: red !important;
  background-color: #fa0000 !important;
}

.big-head-spec-four {
  font-size: 2rem;
  font-weight: 700;
  color: black;
}

.radio-btn-border-changer {
  .ant-radio-inner {
    border-color: #fa0000 !important;
    border-width: 2px;
  }
}

:where(.css-dev-only-do-not-override-ixblex).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: #fa0000 !important;
}

:where(.css-ixblex).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: #fa0000;
}

.radio-btn-border-changer .ant-radio-inner {
  border-color: #fa0000 !important;
  border-width: 2px;
}

.border-remove {
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

.top-row {
  border-top: var(--borderColor);
  border-right: var(--borderColor);
}

.rb {
  border-right: var(--borderColor);
}

.tb {
  border-top: var(--borderColor);
}

.lb {
  border-left: var(--borderColor);
}

.bb {
  border-bottom: var(--borderColor);
}

.rrb {
  border-right: none;
}

.rtb {
  border-top: none;
}

.rlb {
  border-left: none;
}

.rbb {
  border-bottom: none;
}

.bca {
  font-weight: 900;
  color: white;
  background-color: #fa0000;
}

.bold {
  font-weight: 900;
}

.mfont {
  font-size: 1.5rem;
}

.syr-text {
  font-size: 1.2rem;
  width: -webkit-fill-available;
  width: -moz-available;
}

@media only screen and (max-width: 1024px) {
  .syr-text {
    max-width: 20rem !important;
  }

  .align {
    width: 16.5% !important;
  }
}

.width {
  max-width: 30rem;
}

.header-text {
  background-color: #8b8b8bba;
}

.radio-mt {
  margin-top: 10px;
}

.boder-none {
  border: none;
}

body {
  background-image: linear-gradient(180deg,
      var(--bs-body-secondary-bg),
      var(--bs-body-bg) 100px,
      var(--bs-body-bg));
}

.pricing-header {
  max-width: 700px;
}

.main-cont {
  margin-left: 7%;
  margin-top: 1%;
}

.div-cont {
  margin-top: 30px;
  border-radius: 20px;
  border: 1px solid black;
  height: 30em;
  overflow: hidden;
  width: 20rem;
}

.mr-left {
  margin-left: 10px;
}

.icon-color {
  color: #e4003b;
}

.text-margin {
  margin-top: 10px;
}

.btn-size {
  width: 150px !important;
  height: 50px !important;
}

.heading {
  background: lightgray;
  border-bottom: 2px solid whitesmoke;
  height: 5rem;
  padding-top: 10px;
}

.report-font {
  font-size: 28px;
}

.report-txt-font {
  font-size: 16px;
  color: black;
}

.align-text {
  text-align-last: center;
}

.top-margin {
  margin-top: 5px;
}

.num-btn-color {
  background-color: black !important;
}

.play-icon-list {
  list-style: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  line-height: 25px;
  text-indent: -25px;
}

.play-icon-list li::before {
  content: "\25B6";
  /* Unicode character for the play icon */
  margin-right: 10px;
  color: #e4003b;
}

.font {
  font-size: 16px;
}

.content-card {
  height: 17rem;
}
.select-container{
  margin-left: 15rem;
}

.page-title{
  display: flex;
  align-items: flex-start;
}


@media (min-width: 360px) and (max-width: 390px) {
  .page-title{
    margin-left: 1.2rem;
  }
  .select-container{
    margin-left: 5%;
  }
  
}

@media (min-width: 916px) and (max-width: 1280px) {
  .select-container{
    width: 58.5rem;
  }
  .mrleft-zero{
    margin-left: 0px;
  }
  .mrleft-20{
    margin-left: 20px;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .select-container{
    margin-left: 12rem;
    gap: 0px !important;
  }
}
@media (max-width: 480px){
    .select-container{
      margin-left: 2.7rem !important;
      gap: 0px !important;
    }
  }

@media (max-width: 916px) {
  .mr-left{
    margin-left: 0px;
  }
}
@media (max-width: 1024px) {
  .select-container{
    margin-left: 8rem;
  }
}