:root {
  --color: #e4003b;
}

.nav-adjuster {
  nav {
    width: 100%;
    background-color: var(--color) !important;
    color: #fff !important;
    height: 12vh;
    /* float: left; */
    display: flex;
    justify-content: flex-end;
  }

  .btn-primary {
    color: var(--color);
    background-color: white;
    font-size: 21px !important;
    border-radius: 0 !important;
    border: none;
    font-weight: 600;
    padding: 5px 10px;
  }

  .btn-primary:hover {
    background: black;
    color: white;
  }

  .new-con-for-nav {
    display: flex;
  }

  .ul-for-nav {
    list-style: none;
    margin-bottom: 0px !important;
  }

  .expanded {
    background-color: var(--color) !important;
  }

  .nav-list-items {
    color: white;

    a {
      font-weight: 600;
      font-size: 1.2rem;
      color: white;
    }

    a:hover {
      color: black;
    }

    padding: 6px;
  }

}

.ul-for-nav li.active {
  color: var(--color) !important;
  position: relative;
  background-color: white;

  a {
    color: #e4003b;
  }
}

@media screen and (max-width: 768px) {
  .btn-primary {
    width: 100%;
    text-align: start;
  }

  .nav-list-items a {
    padding-left: 10px;
  }
}

//////////////////////////////////////////////////////////////////////

.navigation {
  height: 60px;
  width: 40%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}

.navigation-menu {
  // margin-left: auto;
  margin-right: 10vw;
}

.navigation-menu ul {
  display: flex;
  align-items: center;
  padding: 0;
}

.navigation-menu li {
  // removes default disc bullet for li tags and applies margin to left & right side
  list-style-type: none;
}

.navigation-menu li a {
  // increases the surface area of the anchor tag to span more than just the anchor text
  text-decoration: none;
  // display: block;
  width: 100%;
}

.hamburger {
  // removes default border on button element
  // // positions the icon to the right and center aligns it vertically
  display: none;
}

@media screen and (max-width: 1080px) {
  .hamburger {
    display: block;
  }
}

@media screen and (max-width: 1080px) {
  .navigation-menu ul {
    display: none;
  }
}

@media screen and (max-width: 1080px) {
  .navigation-menu ul {
    // navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar)
    position: absolute;
    margin-top: revert;
    left: 0;
    // stacks the li tags vertically
    // makes menu span full height and width
    width: 100%;
    height: 100vh;
    background-color: var(--color);
  }

  .navigation-menu li {
    // centers link text and strips off margin
    margin: 0px 0.5rem;
  }

  .navigation-menu li a {
    color: black;
    // increases the surface area of the anchor tag to span the full width of the menu
    width: 100%;
  }

  .navigation-menu li:hover {
    background-color: #eee;
  }
}

@media screen and (max-width: 1080px) {
  .navigation-menu.expanded ul {
    display: block;
    z-index: 1;
  }

  .nav-adjuster nav {
    height: 8vh;
  }
}

.new-allignment {
  display: flex;
  align-items: center;
}