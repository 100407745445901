.ant-btn-circle:hover {
	border-color: #e4003b !important;
	color: #e4003b !important;
}

.ant-btn-circle:focus {
	border-color: #e4003b !important;
	color: #e4003b !important;
}

.custom-button-width {
	width: 20em !important;
	color: white !important;
	text-align: left;
}

.customize-report-skip-button:hover {
	border-color: #e4003b;
	color: #e4003b;
}

.custom-button-width:hover {
	color: white;
}

.self-service-layout {
	background-color: white !important;
	height: 100vh;
	justify-content: space-between;
}

.ant-page-header-heading-extra {
	margin: 15px 3px;
}

.ant-steps-item-icon {
	margin-top: 5px;
	border: 1px solid #e4003b !important;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
	background: #ffff;
}

.ant-steps-icon {
	color: #e4003b !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
	background-color: #e4003b !important;
}

.ant-steps-item-tail::after {
	background-color: #e2cece !important;
}

.custom-btn-color {
	background-color: #e4003b;
	background: #e4003b;
}

.ant-steps-vertical>.ant-steps-item:not(:last-child)>.ant-steps-item-container>.ant-steps-item-tail {
	height: 65px;
}

.customize-report-skip-btn:hover {
	border-color: #e4003b;
	color: #e4003b;
}

.ant-input:hover {
	border-color: #e4003b;
}

.ant-input:focus {
	border-color: #e4003b;
	-webkit-box-shadow: 0 0 0 2px rgba(241, 9, 48, 0.2);
	box-shadow: 0 0 0 2px rgba(241, 9, 48, 0.2);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
	border-color: #e4003b;
}

.ant-input-affix-wrapper-focused {
	border-color: #e4003b;
	-webkit-box-shadow: 0 0 0 2px rgba(241, 9, 48, 0.2);
	box-shadow: 0 0 0 2px rgba(241, 9, 48, 0.2);
}

.ant-input-affix-wrapper:hover {
	border-color: #e4003b;
}

.ant-picker:hover {
	border-color: #e4003b;
}

.ant-picker:focus {
	border-color: e4003b;
	-webkit-box-shadow: 0 0 0 2px rgba(241, 9, 48, 0.2);
	box-shadow: 0 0 0 2px rgba(241, 9, 48, 0.2);
}

.ant-picker-focused {
	border-color: #e4003b;
	-webkit-box-shadow: 0 0 0 2px rgba(241, 9, 48, 0.2);
	box-shadow: 0 0 0 2px rgba(241, 9, 48, 0.2);
}

.select-am-pm:hover {
	border-color: #e4003b !important;
}

.custom-form-design .ant-form-item {
	margin: 10px 8px !important;
}

.ant-space-item .ant-form-item {
	margin: 7px 8px !important;
}

.ant-space-align-center {
	flex-wrap: wrap !important;
}

.custom-scrollable-div {
	overflow: auto;
	max-height: 7rem;
	min-height: auto;
	margin-bottom: 1rem;
}

.footer-container {
	display: flex;
}

.activebigscreen {
	display: none;
}

@media screen and (max-width: 575px) {
	.v-alligner-formodal {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.custom-adjuster-for-modal {
		.ant-modal-content {
			width: 95%;
		}
	}
}

@media screen and (max-width: 330px) {
	.custom-button-width {
		width: 18em !important;

	}

	.abc {
		display: none;
	}


	.custom-adjuster-for-modal {
		.ant-modal-body {
			padding: 6px;
		}
	}
}

.h-adjuster-formodal {
	display: flex;
}

.customize-icon {
	color: #e4003b;
	font-size: 30px;
	margin-left: 5px;
	margin-right: 0px;
}

.dragger {

	.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
		color: #e4003b !important;
	}

	.ant-upload.ant-upload-drag .ant-upload-btn {
		max-width: 20rem !important;
		min-width: 20rem !important;
	}

	.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
		border-color: #e4003b !important;
	}
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button'] {
	margin-top: 10px !important;
}

.ant-steps-item-tail {
	margin-top: 10px !important;
}

.checkbox-verizon {

	pointer-events: none;
	display: flex;
	flex-direction: column;
	text-align: left;
	font-size: 17px;

	.ant-checkbox-wrapper+.ant-checkbox-wrapper {
		margin-left: 0px !important;
	}

	.samples {
		pointer-events: all !important;
	}

	.verizon-row {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
	}

	.fileName {
		margin-bottom: 0px !important;
		font-size: 13px !important;
	}
}

.fmcsa-modal {
	.ant-modal-content {
		height: 100% !important;
	}
}


.getting-started {
	background-color: red;
	font-weight: 700;
	color: white;
	font-size: 40px;
	max-height: 3.5em;
}

.resource-item-color {
	color: lightgrey !important;
}

.logo {
	width: 40% !important;
	margin-top: -0.5rem;
}

.pages {
	color: #316685;
	text-align: center;
	font-size: calc(1.5rem + 2vw);
	margin-top: 10%;
}

.navbar {
	background-color: #ffff;
	height: 80px;
	width: 97%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	position: sticky;
	top: 0;
	z-index: 20;
}

.user-btn {
	margin-bottom: 15px;
	padding: 1px;
}

.nav-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 80px;
}

.main-nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.nav-cont {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}

.main-container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.nav-logo {
	align-items: center;
	margin-left: -20px;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
}

.nav-menu {
	display: flex;
	list-style: none;
	text-align: center;
	margin-right: -1rem;
}

.nav-links {
	color: #000000;
	text-decoration: none;
	padding: 0.5rem 1rem;
	height: 100%;
	border-bottom: 3px solid transparent;
}

.nav-links:hover {
	color: #e4003b;
}

.fa-code {
	margin-left: 1rem;
}

.nav-item {
	line-height: 40px;
	margin-right: 1rem;
}

.nav-item::before {
	content: "";
	display: block;
	height: 3px;
	width: 0;
	background: transparent;
	transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
	width: 100%;
	color: #e4003b;
}

:where(.css-1vtf12y) a:hover {
	color: #e4003b;
}

:where(.css-dev-only-do-not-override-1vtf12y) a:hover {
	color: #e4003b;
	background-color: #fff;
}

.nav-item.active {
	color: #000000;
	border: 1px solid #fff;
}

.nav-icon {
	display: none;
}

.drop-icon {
	display: none;
}

@media (min-width: 360px) and (max-width: 599px) {
	.nav-icon {
		display: block;
		position: absolute;
		top: 0;
		right: -5%;
		transform: translate(-100%, 60%);
		font-size: 1.8rem;
		cursor: pointer;
		color: #000000;
	}

	.user-btn {
		margin-bottom: 1%;
		padding: 1px;
	}
}

@media (min-width: 600px) and (max-width: 799px) {
	.nav-icon {
		display: block;
		position: absolute;
		top: 0;
		right: -3%;
		transform: translate(-100%, 60%);
		font-size: 1.8rem;
		cursor: pointer;
		color: #000000;
	}

	.user-btn {
		margin-bottom: 1%;
		padding: 1px;
	}
}

@media (min-width: 800px) and (max-width: 959px) {
	.nav-icon {
		display: block;
		position: absolute;
		top: 0;
		right: -3%;
		transform: translate(-100%, 60%);
		font-size: 1.8rem;
		cursor: pointer;
		color: #000000;
	}

	.user-btn {
		margin-bottom: 0%;
		padding: 1px;
	}
}

@media screen and (max-width: 960px) {
	.nav-menu {
		display: flex;
		flex-direction: column;
		width: 104%;
		border-top: 1px solid #fff;
		position: absolute;
		top: 80px;
		left: -110%;
		opacity: 1;
	}

	.nav-menu.active {
		background: #e4003b;
		left: 0px;
		opacity: 1;
		z-index: 1;
	}

	.nav-item .active {
		border: none;
	}

	.nav-item {
		margin-left: -3rem;
	}

	:where(.css-1vtf12y) a:hover {
		color: white;
	}

	.nav-links {
		color: #fff;
	}

	.nav-links ::after :hover {
		color: #fff;
		display: table;
	}

	.drop-icon {
		display: inline;
	}

}