.ssr-search-text-field:hover {
	border-color: #e4003b;
}

.ssr-search-text-field:focus {
	border-color: #e4003b;
}

.ant-btn:focus .ant-btn:hover .ant-btn:active .ant-btn::placeholder {
	border-color: #e4003b !important;
}

.refresh-btn:hover{
	color: var(--color);
	border-color: var(--color);
}

.refresh-btn:focus {
    color: #e4003b;
    border-color: #e4003b;
    background: #fff;
}

.reports-view-adjuster{
	.ant-pagination-item-active a {
		color: #e4003b !important;
	}

	.ant-pagination-item-active {
		border-color: #e4003b;
	}

	.ant-pagination-item a:hover {
		color: var(--color);	
	}

	.ant-pagination-item:hover {
		color: var(--color);
		border-color: var(--color);
	}

	.ant-pagination-item-link:hover{
		color: var(--color);
		border-color: var(--color);
	}

	.ant-select-selector:hover{
		border-color: var(--color) !important;
	}

	 .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
		border-color: var(--color);
		box-shadow: 0 0 0 0.5px rgb(228, 0, 59);
	 }

	 .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
		color: var(--color);
	 }
}
