.ssr-search-text-field:hover {
	border-color: #2980b9;
}

.ssr-search-text-field:focus {
	border-color: #2980b9;
}

.ant-btn:focus .ant-btn:hover .ant-btn:active .ant-btn::placeholder {
	border-color: #e4003b !important;
}

.ant-pagination-item-active a {
	color: #e4003b !important;
}

.ant-pagination-item-active {
	border-color: #e4003b;
}

.ant-pagination-item a:hover {
	color: var(--color);
}

.ant-pagination-item:hover {
	color: var(--color);
	border-color: var(--color);
}

.ant-pagination-item-link:hover {
	color: var(--color) !important;
	border-color: var(--color) !important;
}

.ant-select-selector:hover {
	border-color: var(--color) !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-color: var(--color);
	box-shadow: 0 0 0 0.5px rgb(228, 0, 59);
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
	color: var(--color);
}

.ssr-table {
	height: 67vh;
	width: auto;
	overflow: auto;
}

// 1600x900
@media (max-width: 1680px) {
	.ssr-table {
		height: 65vh;
	}
}

// 1400x1050, 1440x900
@media (max-width: 1400px) {
	.ssr-table {
		height: 68vh;
	}
}

// 1360x768, 1360x760
@media (max-width: 1366px) {
	.ssr-table {
		height: 61vh;
	}
}

// 1280x1024
@media screen and (max-width: 1280px)and (max-height: 960px) {
	.ssr-table {
		height: 68vh;
	}
}

// 1280x960
@media screen and (max-width: 1280px) and (max-height: 800px) {
	.ssr-table {
		height: 67vh;
	}
}

// 1280x800
@media screen and (max-width: 1280px) and (max-height: 720px) {
	.ssr-table {
		height: 63vh;
	}
}

// 1280x768
@media screen and (max-width: 1280px) and (max-height: 600px) {
	.ssr-table {
		height: 61vh;
	}
}

// 1152x864
@media screen and (max-width: 1152px) {
	.ssr-table {
		height: 65vh;
	}
}

// 1024x768
@media screen and (max-width: 1024px) {
	.ssr-table {
		height: 62vh;
	}
}