.main-con-head{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.menu-con-head{
    display: flex;
    margin-top: 20px;
}
.paddr{
    padding: 15px 25px;
}
.iconAdjusterHead{
    padding: 15px 60px 15px 15px;
    color: #fa0000;
}
.normalFont{
    font-size: 1rem;
    font-weight: 700;
}
.customFont{
    font-size: 1.3rem;

}
.size-for-logo{
    width: 65%;
}
.above-head{
    background-color: #fa0000;
   display: flex;
    justify-content: flex-end;
}
.aboveHeadNormalFont{
    font-size: 3.3rem;
    font-weight: 900;
     color: white;   
     padding-right: 53px;
}
.aboveHeadCustomFont{
    font-size: 4.3rem;
    color: white;
}
.iconid{
    width: 32px;
}

@media (min-width: 300px) and (max-width: 480px) {
    .aboveHeadNormalFont {
        font-size: 2.3rem !important;
    }
    .aboveHeadCustomFont {
        font-size: 3.3rem !important;
    }
  }
