.main-con-five {
  display: flex;
  flex-direction: column;
  margin-top: 1%;
}

.content-cont-five {
  min-width: 80%;
  max-width: 100%;
  display: flex;
}
.ml{
  margin-left: 9.2rem;
}
.mr-five {
  margin-right: 20px;
}

.big-head-normal-five {
  font-size: 1.7rem;
  font-weight: 700;
}

.big-head-spec-five {
  font-size: 2rem;
}

.five-main {
  margin-top: 20px;
}

.t-five {
  display: flex;
  flex-direction: column;
  width: 50%;
  line-height: 1.2;
}

.norm-five-head {
  font-size: 1.5rem;
  font-weight: 700;
  color: black;
}

.spec-five-head {
  font-size: 1.8rem;
  font-weight: 700;
  color: black;
}

.red-col-five {
  color: #fa0000;
}

.red-five {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
}

.txt-input-d-five {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.num-btn-color {
  background: black !important;
}

@media only screen and (max-width: 1024px) {
  .txt-input-d-five {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0px;
    width: 100%;
  }
  .t-five{
    width: 100%;
  }
  .i-five {
    width: 100% !important;
  }
}


.ifield-five {
  border: 1px solid #fa0000;
}

.i-five {
  width: 50%;

  .custom-scrollable-div {
    display: flex;
    flex-direction: column;
  }
}

.annual-feat-five {
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
}

.anndual-div {
  margin-top: 25px;

}

.cont-arr-five {
  display: flex;
  flex-direction: column;
  width: 63rem;
}

.custom-scrollable-div {
  overflow: auto;
  max-height: 7rem;
  min-height: auto;
  margin-bottom: 1rem;
}

.fs-five {
  font-size: 1.2rem;
  color: red;
}

@media (min-width: 360px) and (max-width: 389px) {
  .ml{
    margin-left: 1rem;
  }
  .vol-II-five{
    margin-left: -10px;
  }
  .big-head-normal-five{
    font-size: 1.2rem;
  }
  .five-main{
    padding: 1rem;
    margin-top: 0;
    margin-left: -6.5rem;
  }
  .norm-five-head{
    font-size: 1.2rem;
  }
  .spec-five-head {
    font-size: 1.5rem;
  }
  .red-five {
    font-size: 1rem;
  }
  .t-five{
    width: 100%;
  }
  .i-five{
    width: 100%;
  }
}
@media (max-width: 480px){
  .five-main{
    margin-left: -5.2rem;
    margin-right: -2.8rem;
  }
  }
@media (min-width: 1024px) and (max-width: 1280px) {
  .ml{
    margin-left: 6.2rem;
  }
}
@media (max-width: 1280px){
  .cont-arr-five{
    margin-right: 5rem;
  }
}
@media (max-width: 1024px){
  .ml{
    margin-left: 2.2rem;
  }
}