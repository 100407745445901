.main-containers {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
}

.content-containers {
    max-width: 78%;
}

.heading-cons {
    font-size: 54px;
    letter-spacing: 0.1rem;
    margin-top: 0px;
}

.contact {
    color: var(--color);
    font-weight: 700;
}

.contact-us-content-cons {
    display: flex;
    justify-content: space-between;
    margin-top: 7vh;
    font-size: 22px;
    width: 100%;
}

.right-sides-conss {
    padding: 0px 0px 0px 2px;
    width: 51%;
}

.fact-cons {
    padding: 15px;
    background-color: var(--color);
    color: white;
    display: flex;
    flex-direction: column;
    padding-left: 26px;
    margin-top: 20px;
}

.facts {
    font-size: 30px;
    font-weight: 700;
}

.form-consting {
    width: 45%;
    display: flex;
    justify-content: flex-start;
    padding: 0px 20px 0px 0px;

    .ant-form-horizontal .ant-form-item-label {
        flex-grow: 0;
        width: 50px;
        text-align: left !important;
        max-width: 135px !important;
    }
}

.form-adjuster {
    display: flex;
    flex-direction: column;
}

.labels {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
}

.inputs {
    border: 1px solid #8891aa;
    padding: 8px;
    width: 20vw;
    font-size: 0.9rem;
    border-radius: 7px;
    margin-bottom: 10px;
}

.submit-btn {
    padding: 10px;
    background-color: var(--color);
    border: none;
    border-radius: 5px;
    max-width: 102px;
    max-height: 6vh;
    font-size: 0.9rem;
    font-weight: 500 !important;

    color: white;
    margin-top: 20px;
}

.submit-btn:hover {
    background-color: var(--color) !important;
    color: white !important;
}

.captcha {
    margin-top: 10px;
}

.image-con-us-n {
    width: 100%;
}

.contactus {
    width: 100%;

    .ant-row {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
    }

    .contactus-btn {
        background-color: var(--color);
        color: white;
        font-weight: 500;
        border-radius: 5px;
        width: 100px;
        height: 40px;
        float: right;
        font-size: 0.9rem;
        padding: 0px;
    }

    input {
        border-radius: 5px;
    }
}

@media only screen and (max-width: 700px) {
    .contact-us-content-cons {
        display: block;

        font-size: 22px;
    }

    .fact-cons {
        width: 100%;
    }

    .form-consting {
        width: 100%;
        margin-bottom: 3vh;
    }

    .right-sides-conss {
        width: 93%;
    }
}

@media only screen and (max-width: 930px) {
    .right-sides-conss {
        display: none;
    }

    .content-containers {
        width: 60%;
    }

    .contact-us-content-cons {
        margin-top: 3vh;
    }

    .form-consting {
        width: 100%;

        .ant-form-horizontal .ant-form-item-label {
            flex-grow: 0;
            width: 50px;
            text-align: left !important;
            max-width: 100% !important;
        }
    }
}

@media only screen and (max-width: 700px) {
    .content-containers {
        width: 60%;
    }
}

@media only screen and (max-width: 350px) {
    .content-containers {
        width: 75%;
    }
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .main-containers {
        padding: 1rem !important;
        justify-content: start !important;
    }

    .content-containers {
        width: 85% !important;
    }

    .heading-cons {
        font-size: 40px !important;
        margin-top: 1rem !important;
    }

    .contact-us-content-cons {
        margin-top: 0vh !important;
        width: 97vw !important;
    }

    .contactus input {
        height: 70px !important;
    }

    .contactus input::placeholder {
        font-size: 1rem !important;
    }

    .ant-form-item-required {
        font-size: 1rem !important;
    }

    textarea:where(.css-dev-only-do-not-override-yp8pcc).ant-input:placeholder-shown {
        text-overflow: ellipsis;
        height: 70px;
    }
}

@media (min-width: 1025px) {
    .form-consting {
        margin-left: -11%;
        width: 52%;
        display: flex;
        justify-content: flex-start;
        padding: 0px 20px 0px 0px;
    }
}