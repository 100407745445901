.staticheading {
    font-size: 54px;
    letter-spacing: 0.1rem;
    margin-top: 6vh;
    margin-left: 11vw;
}

.about {
    color: var(--color);
    font-weight: 700;
}



.m-con {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8vh;
    margin-bottom: 18vh;
}

.left-right-con {
    max-width: 75vw;
    display: flex;
}

.leftie {
    max-width: 50%;

}

.rightie {
    max-width: 50%;

}

.number {
    color: var(--color);
    font-weight: 700;
    font-size: 3.1rem;
}

.descrip {
    font-size: 1.4rem;
    line-height: 1.5em;
    margin-left: 1vw;
    color: #1a1818;
}

.point {
    display: flex;
    align-items: center;
}

.find-relevant-fact {
    margin-top: 3vh;
}

.frf-span {
    font-size: 1.4rem;
    color: #1a1818;
    font-weight: 600;
}

.img-how-it-works {
    margin-left: 16px;
    width: 36.3vw;
}

.facts-container {
    padding: 15px;
    background-color: var(--color);
    color: white;
    display: flex;
    flex-direction: column;
    padding-left: 26px;
    margin-left: 16px;
    margin-top: 4vh;
}

.facts {
    font-size: 30px;
    font-weight: 700;
}

.people-span {
    font-size: 1.7em;
}

@media only screen and (max-width: 700px) {
    .left-right-con {
        display: block !important;
    }

    .leftie {
        max-width: 100% !important;
    }

    .rightie {
        max-width: 100% !important;
    }

    .img-how-it-works {
        margin-left: 16px;
        width: 100% !important;
        margin-top: 3vh;
    }

    .m-con {
        margin-top: 5vh;
    }
}

/* Media Query for Mobile Devices */
@media (min-width: 300px) and (max-width: 480px) {
    .main-container-how-it-works {
        padding: 1rem !important;
    }

    .m-con {
        margin-top: 1vh !important;
        margin-bottom: 0px !important
    }

    .point {
        display: flex;
        align-items: flex-start;
    }

    .number {
        color: var(--color);
        font-weight: 700;
        font-size: 2rem;
    }

    .descrip {
        font-size: 1.4rem;
        line-height: 1.5em;
        margin-left: 1vw;
        color: #1a1818;
        margin-top: 2px;
    }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 1523px) {
    .point {
        display: flex;
        align-items: flex-start;
    }

    .number {
        color: var(--color);
        font-weight: 700;
        font-size: 2rem;
    }

    .descrip {
        font-size: 1.4rem;
        line-height: 1.5em;
        margin-left: 1vw;
        color: #1a1818;
        margin-top: 3px;
    }
}