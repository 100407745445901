/* @import "~antd/dist/antd.css"; */

.site-layout-content {
  margin-top: 2%;
  min-height: 280px;
  background: #fff;
}

.site-layout-background {
  margin-top: 15px;
}

.logo {
  width: 210px;
}
.ant-row-rtl .logo {
  float: right;
}
[data-theme="dark"] .site-layout-content {
  background: #141414;
}
.logo img {
  width: inherit;
}

.header-style {
  height: 93vh;
  background: #c0cad9;
  margin-bottom: 45px !important;
}

.ant-layout-header {
  background: #ffffff !important;
}

.ant-menu,
.ant-menu-dark,
.ant-menu-dark,
.ant-menu-sub,
.ant-menu,
.ant-menu-dark,
.ant-menu-sub {
  background: #fff;
}
#components-layout-demo-top-side .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.projects-cards-background {
  padding: 0.7%;
  background: white;
  height: 82vh !important;
}

.menu-style-div {
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}

.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
  border-radius: 15px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff !important;
  background: border-box !important;
}
li.ant-menu-item.ant-menu-item-selected {
  background: border-box;
  color: #e4003b;
}
li {
  background-color: white;
  background: border-box;
}

.ant-layout-header {
  padding: 0 !important;
}

.ant-layout-footer {
  padding: 1% !important;
}

.ant-layout-header .ant-menu {
  border-radius: 15px !important;
  display: block !important;
}

.ant-modal-content {
  min-width: fit-content;
  border-radius: 15px !important;
  margin: 0px auto;
}
.ant-modal-header {
  border-radius: 15px !important;
  margin: 0px auto;
}
.ant-tabs-nav-operations {
  visibility: hidden !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #ffffff;
}

.ant-menu-item:hover {
  color: #e4003b !important;
}
